import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { Toaster } from "@/global-components/components/ui/toaster"
import { User } from '@/global-components/types'

import './App.scss';
import api from '@/global-components/api'
import AuthContext from './context/auth';
import Home from './views/home/Home';
import Login, { LoginProps } from './views/login/Login';
import NoAccess from './views/NoAccess';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);

  const [verifyToken, verifyTokenResults] = useMutation(api.user.mutations.VERIFY_TOKEN);
  const [getUser, getUserResults] = useLazyQuery(api.user.queries.ME);

  useEffect(() => {
    if(api.user.local.isTokenSet()) {
      verifyToken({ variables: { token: api.user.local.getToken() }})
        .then((result : any) => {
          if (result.data.verifyToken.success) {
            getUser()
              .then((result : any) => {
                if (result.data?.me.isStaff) {
                  setLoggedIn(true)
                  setLoading(false)
                  setUser({
                    userId: result.data.me.userId,
                    username: result.data.me.username,
                    isStaff: result.data.me.isStaff,
                    firstName: result.data.me.firstName,
                    lastName: result.data.me.lastName,
                    email: result?.data.me.email,
                    userPreferences: result.data.me.userpreferencesmodelSet[0]
                  })
                } else {
                  navigate("/noaccess")
                }
              })
              .catch((error: any) => console.log(error));
          } else {
            setLoading(false);
            if (location.pathname !== '/signup') navigate("/login");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error)
        })
    } else {
      setLoading(false);
      if (location.pathname !== '/signup') navigate("/login");
    }
  }, [])


  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, user, setUser }}>
      <div className="fm">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:factoryId" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/noaccess" element={<NoAccess />} />
        </Routes>
      </div>
      <Toaster />
    </AuthContext.Provider>
  );
}

export default App;
