import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import api from '@/global-components/api'
import { PrintJob, PrintRequest } from '@/global-components/types'
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '@/global-components/components/ui/table'
import { Button } from '@/global-components/components/ui/button'
import {
  ArrowUpRight,
  CheckCheck,
  Clock,
  Clock4,
  Dot,
  DownloadCloud,
  Frown,
  Hash,
  Info,
  Layers,
  Loader,
  PlusCircle,
  Smile,
  UserCircle,
  X,
} from 'lucide-react'
import helpers from '@/global-components/components/helpers'
import SendToPrinter from './SendToPrinter'
import UpdateProduced from './UpdateProduced'
import Filament from '@/global-components/components/bw/filament/Filament'
import './PrintRequests.scss'
import { Popover, PopoverTrigger } from '@/global-components/components/ui/popover'
import { PopoverClose, PopoverContent } from '@radix-ui/react-popover'
import ProgressPill from '@/global-components/components/ui/ProgressPill'
import usePrintersStore from '@/context/usePrintersStore'

const PrintRequests = () => {
  const _lc_finishedPrintRequestsVisible: boolean = helpers.getLocalStorageItem('finished_print_requests_visible') !== null
    ? helpers.getLocalStorageItem('finished_print_requests_visible') === 'true' ? true : false
    : true
  const { printers, selectionMode, selectedPrinterIds, printRequests, printJobs, setPrintRequests, setPrintJobs} = usePrintersStore()
  const [finishedPrintRequestsVisible, setFinishedPrintRequestsVisible] = useState<boolean>(_lc_finishedPrintRequestsVisible)

  const printRequestsQuery = useQuery(
    api.printRequests.queries.GET_ALL_PRINT_REQUESTS,
    {
      pollInterval: 5000,
    }
  )
  const printJobsQuery = useQuery(
    api.printers.queries.GET_PRINT_JOBS_FOR_PR_IDS,
    {
      variables: {
        prIds: printRequests.map(pr => pr.requestId)
      },
      pollInterval: 5000,
      skip: printRequests.length <= 0
    }
  )

  useEffect(() => {
    if (printRequestsQuery.data) {
      const prsFromServer: PrintRequest[] = printRequestsQuery.data.printRequests
      setPrintRequests(prsFromServer)
      prsFromServer.forEach(pr => {

      })
    }
  }, [printRequestsQuery.data])

  useEffect(() => {
    if (printJobsQuery.data) {
      setPrintJobs(printJobsQuery.data.printJobsForPrIds)
    }
  },[printJobsQuery.data])

  const createLastIssuedCell = (pr: PrintRequest): React.ReactNode => {
    const pj: PrintJob | undefined = printJobs.filter(pj => pj.relatedPrintRequest?.requestId === pr.requestId)[0]
    if (!pj) {
      return null
    } else if (pj.started && !pj.completed && !pj.cancelled) {
      return <div className='flex items-center gap-1'>
        <Loader className='h-4 w-4 animate-spin opacity-30' />
        <Popover>
          <PopoverTrigger asChild>
            <div className='flex items-center gap-1 w-full h-full truncate cursor-pointer'>
              <Info className='h-4 w-4 opacity-30' />
              {/* <div className='opacity-30'>({pj.quantity})</div> */}
            </div>
          </PopoverTrigger>
          <PopoverContent align='start' className="w-64 flex gap-2 p-2 px-3 border-transparent outline-none bg-ui-confirmation-green text-white text-xs font-bold rounded-md shadow-xl -top-16 left-0 relative w-min leading-tight">
            <div className='flex items-center gap-1'>
              Printer: {pj.printer?.name}
            </div>
            <div className='flex items-center gap-1'>
              <UserCircle className='h-4 w-4' /> {pj.createdBy?.firstName}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    } else if(pj.started && !pj.completed && !pj.cancelled && pj.printer?.status?.toLowerCase() === 'printing') {
      return <div className='flex items-center gap-1'>
        <Loader className='h-4 w-4 min-w-4 min-h-4 animate-spin' />
        Printing 
        <div className='opacity-30'>({pj.quantity})</div>
      </div>
    } else if (pj.completed) {
      const time: string = helpers.formatDateToDDMMYY(pj.createdAt, false, true, true)
      return <div className='flex items-center gap-1'>
        <Popover>
          <PopoverTrigger asChild>
            <div className='flex items-center gap-1 w-full h-full truncate cursor-pointer'>
              <div>&#x2705;</div>
              {time}
              <Info className='h-4 w-4 opacity-30' />
              {/* <div className='opacity-30'>({pj.quantity})</div> */}
            </div>
          </PopoverTrigger>
          <PopoverContent align='start' className="w-64 flex gap-2 p-2 px-3 border-transparent outline-none bg-ui-confirmation-green text-white text-xs font-bold rounded-md shadow-xl -top-16 left-0 relative w-min leading-tight">
            <div className='flex items-center gap-1'>
              Quantity: {pj.quantity}
            </div>
            <div className='flex items-center gap-1'>
              <Clock className='h-4 w-4'/> {time}
            </div>
            <div className='flex items-center gap-1'>
              <UserCircle className='h-4 w-4' /> {pj.createdBy?.firstName}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    } else {
      const time: string = helpers.formatDateToDDMMYY(pj.createdAt, false, true, true)
      return <div className='flex items-center gap-1'>
        <Popover>
          <PopoverTrigger asChild>
            <div className='flex items-center gap-1 w-full h-full truncate cursor-pointer'>
              <div>&#x274C;</div>
              {helpers.formatDateToDDMMYY(printJobs.find(pj => pj.relatedPrintRequest?.requestId === pr.requestId)?.createdAt, false, true, true)}
              {/* <div className='opacity-30'>({pj.quantity})</div> */}
              <Info className='h-4 w-4 opacity-30' />
            </div>
          </PopoverTrigger>
          <PopoverContent align='start' className="w-64 flex gap-3 p-2 px-3 border-transparent outline-none bg-ui-denial-red text-white text-xs font-bold rounded-md shadow-xl -top-16 left-0 relative w-min leading-tight">
            <div className='flex items-center gap-1'>
              Quantity: {pj.quantity}
            </div>
            <div className='flex items-center gap-1'>
              <Clock className='h-4 w-4'/> {time}
            </div>
            <div className='flex items-center gap-1'>
              <UserCircle className='h-4 w-4' /> {pj.createdBy?.firstName}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    }
  }

  const selectedPrintersOperational = (): boolean => {
    const selectedPrinters = printers.filter(printer => 
      selectedPrinterIds.includes(printer.printerId)
    )
    const hasNonOperationalPrinter = selectedPrinters.some(printer => 
      printer.status.toLowerCase() !== 'operational'
    )
    return !hasNonOperationalPrinter
  }

  return (
    <div className="print-requests max-w-screen mt-4">
      <div className="relative active p-4 rounded-md max-w-full">
        <Table
          className={`relative max-w-full table-fixed ${
            printRequests.length <= 0 && 'animate-pulse'
          }`}>
          <TableHeader className="relative text-xs text-bw-green hover:bg-transparent">
            <TableRow className="hover:bg-transparent">
              <TableHead className="w-40">Produced</TableHead>
              <TableHead className="w-14">ID</TableHead>
              <TableHead className="w-32">Created at</TableHead>
              <TableHead className="w-24">User</TableHead>
              <TableHead className="min-w-0 w-auto">File</TableHead> {/* Flexible Column */}
              <TableHead className="w-56">Filament</TableHead>
              <TableHead className="w-32 whitespace-nowrap">Running on</TableHead>
              <TableHead className="w-36 whitespace-nowrap">Last print job</TableHead>
              <TableHead className="min-w-64 text-right"></TableHead>
            </TableRow>
          </TableHeader>
          {printRequests.length <= 0 && (
            <TableBody>
              <TableRow className='w-full'>
                <TableCell colSpan={100} className='text-center'>
                  <div
                    className={`flex items-center justify-center text-xs gap-2 whitespace-nowrap py-2 ${
                      printRequests.length > 0 && 'hidden'
                    }`}>
                    <Loader className="h-4 w-4 animate-spin" /> <span className='opacity-30'>Loading print requests</span>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {printRequests.length > 0 && (
            <TableBody>
              {printRequests
                .filter((pr) => pr.produced !== pr.quantity)
                .map((req: PrintRequest, index: number) => {
                  return (
                    <TableRow
                      key={index}>
                      {/* <TableCell className="text-bw-green/30">
                        {req.requestId}
                      </TableCell> */}
                      <TableCell>
                        <div
                          className={`relative flex gap-0 items-center ${
                            req.produced === req.quantity &&
                            'text-ui-confirmation-green'
                          }`}>
                          <ProgressPill progress={req.produced / req.quantity * 100} actual={req.produced + ''} max={req.quantity + ''} /> 
                          <UpdateProduced
                            requestId={req.requestId}
                            produced={req.produced}
                            refetchRequest={() => printRequestsQuery.refetch()}>
                            <Button
                              variant="ghost"
                              size="smallicon"
                              className={`text-ui-confirmation-green p-0 m-0 ${
                                req.quantity === req.produced && 'opacity-30'
                              }`}>
                              <PlusCircle className="h-4 w-4" />
                            </Button>
                          </UpdateProduced>
                        </div>
                      </TableCell>
                      <TableCell className='text-bw-green/30'>
                        {req.requestId}
                      </TableCell>
                      <TableCell>
                        {helpers.formatDateToDDMMYY(req.issuedAt, false, true, true)}
                      </TableCell>
                      <TableCell className='text-bw-green/30'>{req.requestedBy.firstName}</TableCell>
                      <TableCell className="min-w-0 w-auto max-w-full grow truncate">
                        <Popover>
                          <PopoverTrigger asChild>
                            <div className='w-full h-full truncate cursor-pointer'>
                              {req.file.fileName}
                            </div>
                          </PopoverTrigger>
                          <PopoverContent className="w-64 flex flex-col gap-4 p-2 px-3 border-transparent outline-none bg-bw-green text-white text-xs font-bold rounded-md shadow-xl -top-16 left-0 relative w-min leading-tight">
                            {req.file.fileName}
                          </PopoverContent>
                        </Popover>
                      </TableCell>
                      <TableCell>
                        {/* {req.filament.material.displayName} - {req.filament.colour.displayName} */}
                        <div className="flex items-center gap-1">
                        <Filament
                            color={req.filament.colour.hexCode || '#333333'}
                            active={true}
                            small={false}
                            med={true}
                            filled
                          />
                          {req.filament.material.displayName} - {req.filament.colour.displayName}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='flex relative gap-1 flex-wrap max-h-10 overflow-scroll'>
                          {printJobs
                            .filter(pj => pj.relatedPrintRequest?.requestId === req.requestId)
                            .filter(pj => pj.completed === false)
                            .filter(pj => pj.cancelled === false)
                            .map((pj:PrintJob, index:number) => {
                              if (pj.printer?.status?.toLowerCase() === 'printing') {
                                return (
                                  <ProgressPill key={index} className='animate-pulse' progress={pj.progress ? pj.progress : 0} actual={pj.printer?.name ? pj.printer?.name : ''} small />
                                )
                              } else {
                                return null
                              }
                            })
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        {createLastIssuedCell(req)}
                      </TableCell>
                      <TableCell className="flex justify-end pr-2">
                        <div className="flex items-center gap-1">
                          <Button
                            variant="bwsecondary"
                            onClick={() =>
                              helpers.openInNewTab(
                                `https://beta.batch.works/product/${req.relatedProduct?.productVersion[0].reference}`
                              )
                            }>
                            <ArrowUpRight className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="bwsecondary"
                            className='max-xl:hidden'
                            onClick={() =>
                              helpers.openInNewTab(req.file.presignedUrl)
                            }>
                            <DownloadCloud className="h-4 w-4" />
                          </Button>
                          <SendToPrinter printRequest={req}>
                            {selectionMode 
                              ? <Button variant="bwprimary" disabled={selectedPrinterIds.length === 0 || !selectedPrintersOperational()}>
                                  {selectedPrinterIds.length === 0 || !selectedPrintersOperational()
                                    ? 'Select operational printers'
                                    : 'Print on ' + selectedPrinterIds.length + ' printers'
                                  }
                                </Button>
                              : <Button variant="bwprimary">
                                  Print
                                </Button>
                            }
                          </SendToPrinter>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          )}
        </Table>
      </div>
      {printRequests.filter((pr) => pr.produced === pr.quantity).length > 0 && (
        <div className="finished p-4 rounded-md relative">
          <div 
            className="text-xs text-bw-green uppercase font-bold flex items-center justify-center gap-2 mb-5 px-1 py-2 cursor-pointer opacity-30 hover:opacity-100"
            onClick={() => {
              const value: boolean = !finishedPrintRequestsVisible
              setFinishedPrintRequestsVisible!(value)
              helpers.setLocalStorageItem('finished_print_requests_visible', String(value))
            }}>
            <div className="h-[1px] bg-bw-green/50 grow"></div>
            <div className="flex items-center gap-1">
              <CheckCheck className="h-3 w-3" />
              {/* Completed print requests */}
            </div>
            <div className="h-[1px] bg-bw-green/50 grow"></div>
          </div>
          <Table
            className={`relative max-w-full table-fixed 
              ${printRequests.length <= 0 && 'animate-pulse'}
              ${finishedPrintRequestsVisible ? '' : 'hidden'}
            `}>
            <TableHeader className="relative text-xs text-bw-green hover:bg-transparent">
              <TableRow className="hover:bg-transparent">
                {/* <TableHead className="w-[20px]">ID</TableHead> */}
                <TableHead className="w-40"></TableHead>
                <TableHead className="w-14"></TableHead>
                <TableHead className="w-32"></TableHead>
                <TableHead className="w-24"></TableHead>
                <TableHead className="min-w-0 w-auto"></TableHead> {/* Flexible Column */}
                <TableHead className="w-56"></TableHead>
                <TableHead className="w-32 whitespace-nowrap"></TableHead>
                <TableHead className="w-36 whitespace-nowrap"></TableHead>
                <TableHead className="min-w-64 text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {printRequests
                .filter((pr) => pr.produced === pr.quantity)
                .map((req: PrintRequest, index: number) => {
                  return (
                    <TableRow
                      key={index}>
                      {/* <TableCell className="text-bw-green/30">
                        {req.requestId}
                      </TableCell> */}
                      <TableCell>
                        <div
                          className={`relative flex gap-0 items-center ${
                            req.produced === req.quantity &&
                            'text-ui-confirmation-green'
                          }`}>
                          <ProgressPill progress={req.produced / req.quantity * 100} actual={req.produced + ''} max={req.quantity + ''} /> 
                          <UpdateProduced
                            requestId={req.requestId}
                            produced={req.produced}
                            refetchRequest={() => printRequestsQuery.refetch()}>
                            <Button
                              variant="ghost"
                              size="smallicon"
                              className={`text-bw-green p-0 m-0 ${
                                req.quantity === req.produced && 'opacity-30'
                              }`}>
                              <PlusCircle className="h-4 w-4" />
                            </Button>
                          </UpdateProduced>
                        </div>
                      </TableCell>
                      <TableCell className='text-bw-green/30'>
                        {req.requestId}
                      </TableCell>
                      <TableCell>
                        {helpers.formatDateToDDMMYY(req.issuedAt, false, true, true)}
                      </TableCell>
                      <TableCell className='text-bw-green/30'>{req.requestedBy.firstName}</TableCell>
                      <TableCell className="min-w-0 w-auto max-w-full grow truncate">
                        <Popover>
                          <PopoverTrigger asChild>
                            <div className='w-full h-full truncate  cursor-pointer'>
                              {req.file.fileName}
                            </div>
                          </PopoverTrigger>
                          <PopoverContent className="w-64 flex flex-col gap-4 p-2 px-3 border-transparent outline-none bg-bw-green text-white text-xs font-bold rounded-md shadow-xl -top-16 left-0 relative w-min leading-tight">
                            {req.file.fileName}
                          </PopoverContent>
                        </Popover>
                      </TableCell>
                      <TableCell>
                        {/* {req.filament.material.displayName} - {req.filament.colour.displayName} */}
                        <div className="flex items-center gap-1">
                          <Filament
                            color={req.filament.colour.hexCode || '#333333'}
                            active={true}
                            small={false}
                            med={true}
                            filled
                          />
                          {req.filament.material.displayName} - {req.filament.colour.displayName}
                        </div>
                      </TableCell>
                      <TableCell>

                      </TableCell>
                      <TableCell>
                        {createLastIssuedCell(req)}
                      </TableCell>
                      <TableCell className="flex justify-end pr-2">
                        <div className="flex items-center gap-1">
                          <Button
                            variant="bwsecondary"
                            onClick={() =>
                              helpers.openInNewTab(
                                `https://beta.batch.works/product/${req.relatedProduct?.productVersion[0].reference}`
                              )
                            }>
                            <ArrowUpRight className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="bwsecondary"
                            className='max-xl:hidden'
                            onClick={() =>
                              helpers.openInNewTab(req.file.presignedUrl)
                            }>
                            <DownloadCloud className="h-4 w-4" />
                          </Button>
                          <SendToPrinter printRequest={req}>
                            <Button variant="bwprimary" disabled={selectionMode && !selectedPrintersOperational()}>
                              Print
                            </Button>
                          </SendToPrinter>
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  )
}

export default PrintRequests
