import React from 'react'

interface ProgressPillProps {
  progress: number
  actual: string
  max?: string
  small?: boolean
  className?: string
}

const ProgressPill:React.FC<ProgressPillProps> = ({
  progress, actual, max, small, className
}) => {
  return (
    <div className={`relative progress-pill ${className}`}>
      <div className='flex w-min gap-1 flex-wrap max-h-[30px] overflow-y-scroll'>
        <div className={`min-w-min bg-bw-green/40 text-white ${small ? 'text-xxs font-bold rounded-full' : 'text-xs font-bold rounded-sm'} leading-none overflow-hidden`}>
          {max ?
            <div className='relative w-full h-full flex items-center gap-0'>
              <div className={`relative z-10 actual-value p-1 px-2 w-min ${progress === 100 ? 'font-bold' : 'font-normal'}`}>
                {actual}
              </div>
              {progress !== 100 && <div className='relative z-10 text-xxs opacity-30'>|</div>}
              {progress !== 100 &&
                <div className='relative z-10 actual-value p-1 px-2 w-min text-right'>
                  {max}
                </div>
              }
              <div className={`absolute h-full left-0 top-0 ${progress === 100 ? 'bg-ui-confirmation-green' : 'bg-bw-green'} z-0`} style={{width: progress + '%'}}></div>
            </div>
            :
            <div className={`h-full bg-bw-green px-1 py-0.5 w-min`} style={{width: progress + '%'}}>
              {actual}
            </div>
          }
        </div>
      </div>
    </div>
  )
}


export default ProgressPill