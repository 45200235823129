import usePrintersStore from '@/context/usePrintersStore'
import api from '@/global-components/api'
import Filament from '@/global-components/components/bw/filament/Filament'
import PrinterStatusIndicator from '@/global-components/components/bw/PrinterStatusIndicator'
import helpers from '@/global-components/components/helpers'
import { Button } from '@/global-components/components/ui/button'
import { Checkbox } from '@/global-components/components/ui/checkbox'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/global-components/components/ui/dialog'
import { Input } from '@/global-components/components/ui/input'
import { Label } from '@/global-components/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/global-components/components/ui/select'
import { useToast } from '@/global-components/components/ui/use-toast'
import { PrinterType, PrintRequest } from '@/global-components/types'
import { useMutation, useQuery } from '@apollo/client'
import { AlertCircle, AlertTriangle, Check, Diameter, Dot, DownloadCloud, Info, Loader, X } from 'lucide-react'
import React, { ChangeEvent, useEffect, useState } from 'react'

interface SendToPrinterProps {
  children: JSX.Element
  printRequest: PrintRequest
}

const SendToPrinter: React.FC<SendToPrinterProps> = ({children, printRequest}) => {
  const { printers, refetchJobs } = usePrintersStore()
  const { selectionMode, selectedPrinterIds, setSelectionMode, setSelectedPrinterIds } = usePrintersStore()
  const { toast } = useToast()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedPrinterId, setSelectedPrinterId] = useState<string | undefined>(undefined)
  const [quantity, setQuantity] = useState<string>('1')
  const [downloadFile, setDownloadFile] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)

  const [nozzleSize, setNozzleSize] = useState<number | undefined>(undefined)
  const fileAttributesQuery = useQuery(api.products.queries.GET_FILE_ATTRIBBUTES_FOR_FILE, {
    variables: {
      fileId: printRequest.file.fileId
    },
    pollInterval: 10000,
    skip: !printRequest.file || !open,
})
  const automationSettingsQuery = useQuery(api.products.queries.GET_FILE_AUTOMATION,{
    variables: {
      productId: printRequest.relatedProduct?.productId,
      fileId: printRequest.file.fileId
    },
    pollInterval: 10000,
    skip: !printRequest.relatedProduct || !printRequest.file.fileId || !open
  })
  const [issuePrintToPrintersMutation] = useMutation(api.printers.mutations.ISSUE_PRINT_TO_PRINTERS)
  const [automatedGcodeFileMutation] = useMutation(api.printers.mutations.AUTOMATED_GCODE_FILE)

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    // Allow only numbers (no letters or symbols)
    if (!/^\d*$/.test(value)) return;

    // Remove leading zeros unless the input is "0"
    if (value.length > 1 && value.startsWith("0")) {
        value = value.replace(/^0+/, "");
    }

    setQuantity(value);
  }

  useEffect(() => {
    if (fileAttributesQuery.data?.fileAttributesForFile?.nozzleSize) {
      setNozzleSize(fileAttributesQuery.data?.fileAttributesForFile?.nozzleSize)
    } else {
      setNozzleSize(undefined)
    }
  }, [fileAttributesQuery])

  const createAndDownloadFile = async (url:string, filename:string) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        // credentials: 'include', // Uncomment if auth cookies are needed
        // mode: 'cors' // Make sure the server allows this
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
  
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.target = '_blank';
      link.download = filename;
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
  
      console.log('Download triggered!');
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  }
  

  const handleSend = (fileOnly: boolean = false) => {
    setSending(true)

    if (fileOnly) {
      automatedGcodeFileMutation({variables: {
        fileId: printRequest.file.fileId,
        prId: printRequest.requestId,
        quantity: quantity,
      }}).then((result: any) => {
        if (result.data?.automatedGcodeFile?.success) {
          const downloadUrl = result.data?.automatedGcodeFile.file.presignedUrl;
          if (downloadUrl) {
            createAndDownloadFile(downloadUrl, result.data?.automatedGcodeFile.file.fileName)
            setOpen(false)
          } else {
            toast({
              title: "No URL attached to file. Please contact an admin.",
              description: result.data?.automatedGcodeFile.error,
              variant: 'destructive'
            })
          }
        } else {
          toast({
            title: "Error while creating automation file.",
            description: result.data?.automatedGcodeFile.error,
            variant: 'destructive'
          })
        }
      }).finally(() => {
        setSending(false)
      })
    } else {
      const targetPrinterIds: string[] = selectionMode ? selectedPrinterIds : [selectedPrinterId ? selectedPrinterId : '']
      issuePrintToPrintersMutation({variables: {
        printerIds: targetPrinterIds,
        fileId: printRequest.file.fileId,
        quantity: quantity,
        prId: printRequest.requestId,
      }})
        .then((result: any) => {
          if (result.data?.issuePrintToPrinters?.success) {
            setOpen(false)
  
            const downloadUrl = result.data?.issuePrintToPrinters.fileUrl;
  
            if (downloadUrl && downloadFile) {
              createAndDownloadFile(downloadUrl, result.data?.issuePrintToPrinters.fileName)
            }
  
            toast({
              title: "Print file sent to "
                + printers.filter(printer => targetPrinterIds.find(id => printer.printerId === id))?.length
                + " printers",
              variant: 'success'
            })
            
            refetchJobs()
            setSending(false)
            if (selectionMode) {
              setSelectionMode(false)
              setSelectedPrinterIds([])
            }
          } else {
            toast({
              title: "Error sending file to printer",
              description: result.data?.issuePrintToPrinters.error,
              variant: 'destructive'
            })
            setSending(false)
          }
        })
        .catch((error: any) => {
          console.log('send file error: ', error)
          setSending(false)
        })
    }
  }

  const canPrinterReceiveFile = (): boolean => {
    const status: string | undefined = printers.find(printer => printer.printerId === selectedPrinterId)?.status?.toLowerCase()
    if (status === 'operational') {
      return true
    } else if(status === 'ready') {
      return true
    } else {
      return false
    }
  }

  const hasRequiredAutomation = (): boolean => {
    if (Number(quantity) > 1) {
      return automationSettingsQuery.data?.fileAutomation ? true : false
    } else {
      return true
    }
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(value: boolean) => {
        setOpen(value)
      }}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className='max-w-xl m-4'>
        <DialogHeader>
          <DialogTitle className="text-bw-green">
            Print file: <span className='font-normal'>{printRequest.file.fileName}</span>
          </DialogTitle>
        </DialogHeader>
        
        <div className={`flex 
          ${sending && 'opacity-30 pointer-events-none'}
          ${selectionMode ? 'flex-col items-start gap-4' : 'items-center gap-2'}
        `}>
          {selectionMode
            ? <div className='flex flex-col gap-1'>
                <Label>Printers</Label>
                <div className='flex gap-2 p-2  bg-white border border-grey rounded-md text-sm text-bw-green'>
                  {selectedPrinterIds.map((id: string, index: number) => {
                    const printerName: string | undefined = printers.find(printer => printer.printerId === id)?.name
                    return (
                      <div className='bg-bw-grey/40 p-1 px-2 rounded-sm flex items-center '>{printerName}</div>
                    )
                  })}
                </div>
              </div>
            : <div className="flex flex-col gap-1 max-w-full grow">
                <Label>Printer</Label>
                <Select onValueChange={setSelectedPrinterId}>
                  <SelectTrigger className="">
                    <SelectValue placeholder="Choose a printer" />
                  </SelectTrigger>
                  <SelectContent className='max-h-[40vh] overflow-y-auto'>
                    {printers?.map((printer: PrinterType, index: number) => (
                      <SelectItem
                        value={printer.printerId}
                        key={index}
                        className="flex">
                        <div className="relative flex items-center justify-between w-full gap-2">
                          <div className="flex items-center gap-3 py-1">
                            <PrinterStatusIndicator status={printer.status} />
                            <b>{printer.name}</b>
                            <div className='flex items-center text-xs opacity-30 font-semibold gap-1'>
                              <Diameter className="h-4 w-4" />
                              {printer.loadedNozzle} mm
                            </div>
                          </div>
                          
                          {/* <div className="opacity-30 text-xxs">{printer.status}</div> */}
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
          }

          <div className="flex flex-col gap-1 max-w-full">
            <Label>
              {selectionMode
                ? 'Quantity each'
                : 'Quantity'
              }
            </Label>
            <Input
              type="number"
              value={quantity}
              placeholder="Set Quantity"
              onChange={handleQuantityChange}
              className=""
            />
          </div>
        </div>
        <div className="warnings flex flex-col gap-2 pl-2 mb-4 max-w-full">
          <div className="flex items-center gap-1 text-xs">
            <Info className="h-4 w-4 text-ui-warn-orange" />
            {nozzleSize === undefined && !fileAttributesQuery.loading ? (
              <div>
                Nozzle size{' '}
                <span className="text-ui-denial-red">not defined</span>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                Attach nozzle with
                <b>
                  {!fileAttributesQuery.loading ? (
                    nozzleSize + ' mm'
                  ) : (
                    <Loader className="h-4 w-4 animate-spin" />
                  )}
                </b>
                {/* {selectedPrinterId 
                  ? printers.find(p => p.printerId === selectedPrinterId)?.loadedNozzle === nozzleSize
                    ? <Check className='h-4 w-4 text-ui-confirmation-green' />
                    : <div className='flex items-center text-ui-denial-red'>
                        Printer has different nozzle set up. 
                        <div>I've changed it</div>
                    </div>
                  : null
                } */}
              </div>
            )}
          </div>
          <div className="flex items-center gap-1 text-xs">
            <Info className="h-4 w-4 text-ui-warn-orange" />
            Filament{' '}
            <Filament color={printRequest.filament.colour.hexCode || '#333333'} active small filled/>
            <b>
              {printRequest.filament.material.displayName} -{' '}
              {printRequest.filament.colour.displayName} {' '}
              {printRequest.filament.manufacturer &&
                <span className='font-normal opacity-70'>({printRequest.filament.manufacturer?.name})</span>
              }
            </b>{' '}
            needs to be mounted
          </div>
          {!automationSettingsQuery.loading && automationSettingsQuery.data?.fileAutomation ?
            <div className='flex flex-col gap-2'>
              <div className={`flex items-center gap-1 text-xs ${Number(quantity)<=1 && 'hidden'}`}>
                <Info className="h-4 w-4 text-bw-green" />
                Creating automation file may take a few moments
              </div>
              <div className={`flex items-center gap-1 text-xs ${Number(quantity)<=1 && 'hidden'}`}>
                <Info className="h-4 w-4 text-bw-green" />
                Automation parameters currently can be changed <a href={`https://beta.batch.works/product/${printRequest.relatedProduct?.productVersion[0].reference}`} target='_blank' className='underline truncate'>here</a>
              </div>
            </div>
            : <div className={`flex items-center gap-1 text-xs ${Number(quantity)<=1 && 'hidden'}`}>
              <AlertTriangle className="h-4 w-4 text-ui-denial-red" />
              Has no automation. Please create here: <a href={`https://beta.batch.works/product/${printRequest.relatedProduct?.productVersion[0].reference}`} target='_blank' className='underline truncate'>here</a>
            </div>
          }
        </div>

        <DialogFooter>
          <div className='w-full flex items-center justify-between'>
            <div className={`${sending && 'opacity-30 pointer-events-none'}`}>
              <div className={`flex gap-1 items-center 
                ${(!canPrinterReceiveFile() || Number(quantity) < 2) && 'hidden'}
                ${!automationSettingsQuery.data?.fileAutomation && 'hidden'}
              `}>
                <Checkbox checked={downloadFile} onCheckedChange={(checked: boolean) => setDownloadFile(checked)}/>
                <label htmlFor='downloadFile' className='text-sm text-bw-green font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
                  Download Automated File
                </label>
              </div>
            </div>
            <div className="flex gap-1 items-center">
              {/* <Button
                className="flex gap-1 items-center"
                variant="bwsecondary"
                onClick={() => setOpen(false)}>
                Cancel
              </Button> */}
              {selectionMode 
                ? <Button
                    variant="bwconfirm"
                    className="flex gap-1 items-center"
                    disabled={
                      sending || 
                      Number(quantity)<1 || 
                      !hasRequiredAutomation()
                    }
                    type="submit"
                    onClick={() => handleSend()}
                    loading={sending}
                    altLoadingText='Automating (may take a moment)'>
                      Launch on {selectedPrinterIds.length} Printers
                  </Button>
                : ((!canPrinterReceiveFile() && Number(quantity) > 1)
                    ? <Button
                        variant="bwconfirm"
                        className="flex gap-1 items-center"
                        disabled={
                          sending ||
                          !hasRequiredAutomation()
                        }
                        type="submit"
                        onClick={() => handleSend(true)}
                        loading={sending}
                        altLoadingText='Processing Automation'>
                          <DownloadCloud className='h-4 w-4' />
                          Download Automated File
                      </Button>
                    : <Button
                        variant="bwconfirm"
                        className="flex gap-1 items-center"
                        disabled={
                          sending || 
                          !canPrinterReceiveFile() || 
                          Number(quantity)<1 || 
                          !hasRequiredAutomation()
                        }
                        type="submit"
                        onClick={() => handleSend()}
                        loading={sending}
                        altLoadingText='Automating (may take a moment)'>
                          Launch on Printer
                      </Button>
                )
              }
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SendToPrinter