import { useAuth } from '@/context/auth';
import api from '@/global-components/api';
import { Button } from '@/global-components/components/ui/button';
import { User } from '@/global-components/types';
import { useApolloClient } from '@apollo/client';
import React from 'react'

interface NoAccessProps {

}
const NoAccess: React.FC<NoAccessProps> = ({}) => {
  const { setLoggedIn } = useAuth();
  const user: User | null = useAuth().user;
  const client = useApolloClient();
  const logOut = () => {
    setLoggedIn(false);
    api.user.local.removeToken();
    client.clearStore();
    window.location.reload();
  } 
  return (
    <div className='no-access w-full h-full relative flex flex-col gap-2 items-center justify-center text-ui-denial-red'>
      Sorry, but you do not have access to this part of the service.
      <Button variant='bwprimary' onClick={() => logOut()}>Log Out</Button>
    </div>
  )
}

export default NoAccess