import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Printer from './printer/Printer'
import api from '@/global-components/api'
import { PrinterType, PrintJob } from '@/global-components/types'
import { useParams } from "react-router-dom";
import { Button } from "@/global-components/components/ui/button"
import { Input } from '@/global-components/components/ui/input'
import { PlusCircle, MinusCircle, Loader, ThermometerSnowflake, Flame } from 'lucide-react'

import './Printers.scss'
import usePrintersStore from '@/context/usePrintersStore';
import AllPrintersControls from './AllPrintersControls';
import helpers from '@/global-components/components/helpers';

interface PrintersProps {
  isMobile?: boolean
}

const Printers: React.FC<PrintersProps> = ({isMobile=false}) => {
  const { factoryId } = useParams();
  const { printers, setPrinters, jobsRefetchKey, selectionMode, selectedPrinterIds, setSelectedPrinterIds } = usePrintersStore()
  const printersQuery = useQuery(api.printers.queries.GET_ALL_PRINTERS_FOR_FACTORY_ID, {
    variables: {
      factoryId: factoryId ? factoryId : 1
    },
    pollInterval: 5000,
  });

  const activePrintJobsForPrinters = useQuery(api.printers.queries.GET_ACTIVE_PRINT_JOB_FOR_PRINTER_IDS, {
    variables: {
      printerIds: printers
        .filter((printer: PrinterType) => printer.status.toLowerCase() === 'printing')
        .map((printer: PrinterType) => printer.printerId)
    },
    skip: printers.length === 0,
    pollInterval: 20000,
  })

  useEffect(() => {
    if (jobsRefetchKey > 0) {
      console.log('triggering refetch')
      activePrintJobsForPrinters.refetch()
      printersQuery.refetch() 
    } 
  }, [jobsRefetchKey])

  let [newPrinterName, setNewPrinterName] = useState<string>('')
  let [newPrinterUuid, setNewPrinterUuid] = useState<string>('')
  let [newPrinterUiVisible, setNewPrinterUiVisible] = useState<Boolean>(false)
  const [createPrinter] = useMutation(api.printers.mutations.CREATE_PRINTER)
  const createNewPrinter = () => {
    createPrinter({variables: {name: newPrinterName, uuid: newPrinterUuid, factoryId: factoryId ?? 1}})
      .then((result: any) => {
        setNewPrinterUiVisible(false)
      })
      .catch(err => {
        console.log(err)
      })
  }  

  useEffect(() => {
    if (printersQuery.data?.printersInLocation) {
      const printersInLoaction: PrinterType[] = [...printersQuery.data?.printersInLocation || []];
      setPrinters(printersInLoaction);
    }
  }
  ,[printersQuery.data, printersQuery.loading, printersQuery])

  const togglePrinterSelected = (id: string) => {
    if (selectedPrinterIds.includes(id)) {
      setSelectedPrinterIds(selectedPrinterIds.filter(_id => _id !== id))
    } else {
      setSelectedPrinterIds([...selectedPrinterIds, id])
    }
  }

  return (
    <div className='printers-container'>
      <div className={`printers grid gap-2 pt-4 pl-4 pr-4 pb-2 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-9 ${helpers.isMobile() ? 'grid-cols-1' : null}`}>
        {printersQuery.loading ? 
          <div className='w-full h-full flex justify-center items-center aspect-[5/4]'>
            <Loader className='h-4 w-4 animate-spin'/>
          </div>
          :
          printers.map((printer: PrinterType) => 
            <div className='relative' key={printer.printerId}>
              <Printer  
                printer={printer} 
                printJob={activePrintJobsForPrinters.data?.activePrintJobsForPrinterIds?.find((job:PrintJob) => job.printer?.printerId === printer.printerId)}/>
              {selectionMode &&
                <div 
                  className={`absolute cursor-pointer left-0 top-0 w-full h-full z-10 border border-2 border-bg-background-grey outline outline-2 rounded-xl
                    transition-all ease-kibu duration-300 hover:duration-100
                    ${selectedPrinterIds.find(id => printer.printerId === id) ? 'bg-transparent outline-ui-confirmation-green' : 'bg-bw-background-grey/20 outline-bw-grey hover:bg-bw-background-grey/25 hover:outline-ui-confirmation-green/50'}
                  `}
                  onClick={() => togglePrinterSelected(printer.printerId)}>
                </div>
              }
            </div>
          )
        }
        <div className={`add-printer flex items-center justify-center aspect-[5/4]
          ${printersQuery.loading && 'hidden'}
          ${newPrinterUiVisible && 'flex-col justify-between'}`}>
          <Button variant="ghost" size='sm' onClick={() => setNewPrinterUiVisible(!newPrinterUiVisible)}>
            {newPrinterUiVisible ? 
              <div className='text-xs'>Cancel</div>
              :
              <PlusCircle className='icon w-4 h-4'/>
            }
          </Button>
          <div className={`relative flex flex-col gap-1 add-printer-inputs ${newPrinterUiVisible ? '' : 'invisible'}`}>
            <div className='inputs flex flex-col gap-1'>
              <Input type='text' name='printer-name' placeholder="Printer Name" value={newPrinterName} onChange={(e)=>setNewPrinterName(e.target.value)} />
              <Input type='text' name='printer-uuid' placeholder="Printer UUID" value={newPrinterUuid} onChange={(e)=>setNewPrinterUuid(e.target.value)} />
            </div>
            <Button variant="bwconfirm" disabled={newPrinterName === '' || newPrinterUuid  === '' } onClick={() => createNewPrinter()}>Add new printer</Button>
          </div>
        </div>
      </div>
      <AllPrintersControls />
    </div>
  )
}

export default Printers;