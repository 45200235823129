import api from '@/global-components/api'
import { Button } from '@/global-components/components/ui/button'
import { Input } from '@/global-components/components/ui/input'
import { Label } from '@/global-components/components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/global-components/components/ui/popover'
import { useToast } from '@/global-components/components/ui/use-toast'
import { useMutation } from '@apollo/client'
import { Loader } from 'lucide-react'
import React, { useState } from 'react'

interface UpdateProducedProps {
  children: React.JSX.Element
  requestId: string
  produced: number
  refetchRequest: React.FC
}

const UpdateProduced: React.FC<any> = ({children, requestId, produced, refetchRequest}) => {
  const {toast} = useToast()
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>(produced+'')
  const [updateProducedMutation, {loading: updating}] = useMutation(api.printRequests.mutations.UPDATE_PRODUCTION_ON_PRINT_REQUEST)

  const updateProduced = () => {
    updateProducedMutation({variables: {requestId: requestId, addAmount: Number(value)}})
      .then(result => {
        if (result.data?.updateProductionOnPrintRequest.usuccess) {
          toast({
            variant: 'success',
            title: 'Updated',
            duration: 2000,
          })
        } else if (result.data?.updateProductionOnPrintRequest.error) {
          toast({
            variant: 'destructive',
            title: 'Updating produced amount failed',
            description: result.data?.updateProductionOnPrintRequest.error,
            duration: 5000,
          })
        }
      })
      .catch(error => {
        toast({
					variant: 'destructive',
					title: 'Updating produced amount failed',
          description: error,
					duration: 5000,
				})
      })
      .finally(() => {
        refetchRequest()
        setOpen(false)
      })
  }

  const handleConfirm = () => {
    updateProduced()

  }

  
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {children}
      </PopoverTrigger>
      <PopoverContent className='flex flex-col gap-4' align='start' side='right'>
        <div className='text-xs opacity-50 text-center'>How many have you made?</div>
        <div className='flex items-center gap-1'>
          <div className='flex gap-1 items-center'>
            <Input
              className='max-w-32'
              placeholder='Amount'
              value={value}
              onChange={e => setValue(e.currentTarget.value)}>
            </Input>
          </div>
          <Button variant="bwconfirm" size='default' onClick={handleConfirm} disabled={updating}>
            {updating ? 
              <Loader className='h-4 w-4 animate-spin' />
              :'Add'
            }
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default UpdateProduced