import usePrintersStore from '@/context/usePrintersStore'
import api from '@/global-components/api'
import { Button } from '@/global-components/components/ui/button'
import { toast } from '@/global-components/components/ui/use-toast'
import { useMutation } from '@apollo/client'
import { Dot, Factory, Flame, Loader, MousePointer2, ThermometerSnowflake } from 'lucide-react'
import React, { useState, useEffect } from 'react'
import PrintJobHistory from '../printJobHistory/PrintJobHistory'
interface AllPrintersControlsProps {}
const AllPrintersControls: React.FC<AllPrintersControlsProps> = ({}) => {
  const { printers, selectedPrinterIds, setSelectedPrinterIds, selectionMode, setSelectionMode } = usePrintersStore()
  const [heatAllPrinters, { loading: sendingHeatingCommand }] = useMutation(
    api.printers.mutations.HEAT_PRINTERS
  )
  const [coolAllPrinters, { loading: sendingCoolingCommand }] = useMutation(
    api.printers.mutations.COOL_PRINTERS
  )
  const handleHeatAllPrinters = () => {
    if (!printers) return
    const printerIds: string[] = selectedPrinterIds.length > 0 
      ? selectedPrinterIds
      : printers
        .filter((printer) => printer.status.toLowerCase() === 'operational')
        .map((printer) => printer.printerId)

    heatAllPrinters({
      variables: {
        printerIds: printerIds
      },
    }).then((result) => {
      if (result.data.heatPrinters.success) {
        setSelectedPrinterIds([])
        setSelectionMode(false)
        toast({
          title: 'Requested printers are heating up',
          duration: 2000,
        })
      } else {
        toast({
          title: 'Something isnt working. Contact software@batch.works',
          duration: 5000,
          variant: 'destructive'
        })
      }
    })
  }
  const handleCoolAllPrinters = () => {
    if (!printers) return
    const printerIds: string[] = selectedPrinterIds.length > 0 
      ? selectedPrinterIds
      : printers
        .filter((printer) => printer.status.toLowerCase() === 'operational')
        .map((printer) => printer.printerId)

    coolAllPrinters({
      variables: {
        printerIds: printerIds
      },
    }).then((result) => {
      if (result.data.coolPrinters.success) {
        setSelectedPrinterIds([])
        setSelectionMode(false)
        toast({
          title: 'Requested printers are cooling down',
          duration: 2000,
        })
      } else {
        toast({
          title: 'Something isnt working. Contact software@batch.works',
          duration: 5000,
          variant: 'destructive'
        })
      }
    })
  }

  const handleSelectionToggle = () => {
    if (selectionMode) {
      setSelectionMode(false)
      setSelectedPrinterIds([])
    } else {
      setSelectionMode(true)
    }
  }
  return (
    <div className={`all-printers-controls fixed z-10 bottom-0 left-0 flex items-center justify-center w-full gap-2 text-sm p-4 ${!printers.length && 'hidden'}`}>
      <div className='flex gap-2 items-center rounded-2xl bg-white border border-black/5 p-2 shadow-2xl'>
        {/* <div className='text-xs font-bold ml-1 opacity-30'>
          <Factory className='h-3 w-3' strokeWidth={1}/>
        </div> */}
        <Button
          variant='bwprimary'
          size='sm'
          className="flex justify-center gap-1 items-center group"
          disabled={sendingCoolingCommand || sendingHeatingCommand}
          onClick={() => handleSelectionToggle()}>
            {selectionMode
              ? <div className='flex gap-1 items-center'>
                  {/* <MousePointer2 className='h-4 w-4' /> */}
                  Cancel selection
                </div>
              : <div className='flex gap-1 items-center'>
                  {/* <MousePointer2 className='h-4 w-4' /> */}
                  Select printers
                </div>
            }
        </Button>
        <Dot className='h-4 w-4' />
        <Button
          variant='bwprimary'
          size='sm'
          className="flex justify-center gap-1 items-center group"
          disabled={sendingCoolingCommand || sendingHeatingCommand}
          onClick={() => handleHeatAllPrinters()}>
          {sendingHeatingCommand ? (
            <Loader className="h-4 w-4 animate-spin" />
          ) : (
            <Flame className="w-4 h-4 text-ui-notification-red group-hover:text-white" />
          )}
          Heat {selectedPrinterIds.length > 0 ? selectedPrinterIds.length : 'all'} printers
        </Button>
        <Button
          variant='bwprimary'
          size='sm'
          className="flex justify-center gap-1 items-center group"
          disabled={sendingCoolingCommand || sendingHeatingCommand}
          onClick={() => handleCoolAllPrinters()}>
          {sendingCoolingCommand ? (
            <Loader className="h-4 w-4 animate-spin" />
          ) : (
            <ThermometerSnowflake className="w-4 h-4 text-ui-info-purple  group-hover:text-white" />
          )}
          Cool {selectedPrinterIds.length > 0 ? selectedPrinterIds.length : 'all'} printers
        </Button>
        <Dot className='h-4 w-4' />
        <PrintJobHistory />
      </div>
    </div>
  )
}

export default AllPrintersControls
