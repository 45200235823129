import { PrinterType, PrintJob, PrintRequest } from '@/global-components/types'
import { create } from 'zustand'

interface PrintersState {
  printers: PrinterType[]
  printRequests: PrintRequest[]
  printJobs: PrintJob[]
  selectedPrinterIds: string[]
  selectionMode: boolean
  jobsRefetchKey: number
  setPrinters: (printers: PrinterType[]) => void
  setPrintRequests: (printRequests: PrintRequest[]) => void
  setPrintJobs: (printJobs: PrintJob[]) => void
  setSelectedPrinterIds: (ids: string[]) => void
  setSelectionMode: (active: boolean) => void
  refetchJobs: () => void
}

const usePrintersStore = create<PrintersState>((set) => ({
  printers: [],
  printRequests: [],
  printJobs: [],
  selectedPrinterIds: [],
  selectionMode: false,
  jobsRefetchKey: 0,
  setPrinters: (printers: PrinterType[]) => {
    set({ printers })
  },
  setPrintRequests: (printRequests: PrintRequest[]) => {
    set({ printRequests })
  },
  setPrintJobs: (printJobs: PrintJob[]) => {
    set({ printJobs })
  },
  setSelectedPrinterIds: (selectedPrinterIds: string[]) => {
    set({ selectedPrinterIds })
  },
  setSelectionMode: (selectionMode: boolean) => {
    set({ selectionMode })
  },
  refetchJobs: () => {
    set((state) => ({ jobsRefetchKey: state.jobsRefetchKey + 1 }))
  }
}))

export default usePrintersStore