import React from 'react'
import helpers from '../helpers'
import { Loader, LoaderCircle, LoaderPinwheel, ZapOff } from 'lucide-react'

interface PrinterStatusIndicatorProps {
  status: string | undefined
  showText?: boolean
}

const PrinterStatusIndicator: React.FC<PrinterStatusIndicatorProps> = ({status, showText=false}) => {
  const getTailwindClassesForStatus = (status: string): string => {
    if (status.includes("busy")) {
      return " bg-ui-grey animation-pulse";
    }
    if (status.includes("ready") || status.includes("operational")) {
      return " bg-ui-confirmation-green";
    }
    if (status.includes("printing")) {
      return " border border-[3px] border-t-0 border-r-0 border-l-0 border-ui-confirmation-green animate-pulse animate-spin";
    }
    if (status.includes("inactive") || status.includes("pause") || status.includes("inoperational")) {
      return " bg-bw-grey";
    }
    if (status.includes("error")) {
      return " bg-ui-denial-red";
    }
    if (status.includes("idle")) {
      return " bg-ui-denial-red animate-pulse";
    }
    if (status.includes("cancelling")) {
      return " border border-2 border-t-0 border-r-0 border-ui-warn-orange animate-pulse animate-spin";
    } else {
      return ""
    }
  }

  if (!status) {
    return null
  } else if (status.toLowerCase() === 'unresponsive') {
    return (
      <div className='flex gap-1 items-center'>
        <ZapOff className='h-3 w-3' />
        {showText && 
          <div className='text-xs font-medium'>OFFLINE</div>
        }
      </div>
    )
  } else if(status.toLowerCase() === 'printing') {
    return (
      <div className='flex gap-1 items-center'>
        <LoaderCircle className={`w-4 h-4 text-ui-confirmation-green animate-spin`} />
      </div>
    )
  } else {
    return (
      <div className='flex gap-1 items-center'>
        <div className={`rounded-xl 
          ${getTailwindClassesForStatus(status.toLowerCase() || '')}
          w-2 h-2`}>
        </div>
        {showText && 
          <div 
            className={`text-xs font-medium
            ${status.toLowerCase()==='error' && 'text-ui-denial-red'}`}>
              {status !== 'OPERATIONAL' ? status : ''}
          </div>
        }
      </div>
    )
  }
}

export default PrinterStatusIndicator