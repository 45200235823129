import { useAuth } from '@/context/auth'
import usePrintersStore from '@/context/usePrintersStore'
import BwSymbol from '@/global-components/brand-assets/BwSymbol'
import PrinterStatusIndicator from '@/global-components/components/bw/PrinterStatusIndicator'
import helpers from '@/global-components/components/helpers'
import { PrinterType, PrintRequest, User } from '@/global-components/types'
import { Dot, Route, UserCircle2 } from 'lucide-react'
import React from 'react'

interface StatusBarProps {

}

const StatusBar: React.FC<StatusBarProps> =({}) => {
  const { printers, printRequests, printJobs } = usePrintersStore()
  const user: User | null = useAuth().user;

  return (
    <div className='status-bar-container w-full sticky z-30 left-0 top-0 '>
      <div className='status-bar w-full p-3 pt-4 pb-0'>
        <div className='flex items-center uppercase justify-between text-xs bg-white rounded-full w-full h-full p-4 shadow-statusbar'>
          <div className='flex items-center gap-4'>
            <div className='flex items-center gap-2 font-medium'>
              <BwSymbol small className='w-5 h-5' />
              Factory Manager <span className='opacity-30 font-normal'>v0.83</span>
            </div>
          </div>
          <div className='flex items-center gap-6 pr-4'>
            <div className='flex items-center gap-2 font-medium normal-case opacity-50'>
              Last print job {helpers.formatDateToDDMMYY(printJobs.slice(-1)[0]?.createdAt, false, true, true)} by {printJobs.slice(-1)[0]?.createdBy?.firstName} on {printJobs.slice(-1)[0]?.printer?.name}
            </div>
            <Dot className='w-4 h-4 opacity-30' />
            <div className='flex items-center gap-2 font-medium'>
              <Route className='h-4 w-4' />
              {printRequests?.filter((pr: PrintRequest) => pr.produced < pr.quantity)?.length}
            </div>
            <div className='flex items-center gap-2 font-medium'>
              <PrinterStatusIndicator status='operational' />
              {printers?.filter((printer:PrinterType) => printer.status.toLowerCase() === 'operational').length}
            </div>
            <div className='flex items-center gap-2 font-medium'>
              <PrinterStatusIndicator status='printing' />
              {printers?.filter((printer:PrinterType) => 
                printer.status.toLowerCase() === 'printing'
                || printer.status.toLowerCase().includes('from_sd')
              ).length}
            </div>
            <div className='flex items-center gap-2 font-medium'>
            <PrinterStatusIndicator status='error' />
            {printers?.filter((printer:PrinterType) => printer.status.toLowerCase() === 'error').length}
            </div>
            <Dot className='w-4 h-4 opacity-30' />
            <div 
              className='flex items-center gap-2 font-medium cursor-pointer'
              onClick={() => helpers.openInNewTab('https://beta.batch.works/account')}>
              <UserCircle2 className='h-4 w-4' />
              {user?.firstName}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default StatusBar