import { gql } from '@apollo/client';

let GRAPHQL_URI: string = 'https://bw-api-dev.onrender.com/';
let COOKIE_URL: string = '.batch.works'
let LOCAL: boolean = false

if (process.env.REACT_APP_BRANCH === 'beta') {
  GRAPHQL_URI = 'https://bw-api-beta.onrender.com/';
} else if (process.env.REACT_APP_BRANCH === 'local') {
  GRAPHQL_URI = 'http://localhost:8000/';
  LOCAL = true
}

/* GRAPH QL INC. */
const PING = gql`
  query Ping {
    ping
  }
`;


/*****************************************************************/
/*                          PRODUCTS                             */
/*****************************************************************/

const GET_PRODUCTS = gql`
  query GetProducts($includeFiles: Boolean!, $includeMessages: Boolean!) {
    products {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      sku
      createdAt
      statusOptions
      latestVersion
      statusSeen
      lastModified
      productVersion {
        reference
      }
      createdBy {
        lastLogin
        isSuperuser
        firstName
        lastName
        isStaff
        isActive
        dateJoined
        userId
        email
        username
      }
      filemodelSet @include(if: $includeFiles) {
        fileId
        fileName
        fileSize
        file
        timestamp
        presignedUrl
        printable
      }
      messagemodelSet @include(if: $includeMessages) {
        messageId
        read
        sender {
          userId
        }
      }
    }
  }
`;

const GET_PRODUCTS_FOR_USER = gql`
	query GetProductsForUser($userId: ID!) {
		productsForUser(userId: $userId) {
			productId
			productTitle
      sku
			status
			statusSeen
			latestVersion
			groupId {
				groupId
				groupTitle
			}
			organisations {
				organisationId
				name
			}
			productVersion {
				reference
			}
			createdBy {
				userId
			}
      createdAt
		}
	}
`

const GET_FILES_FOR_PRODUCT = gql`
  query GetFilesForProduct($productId: ID!) {
    filesForProduct(productId: $productId) {
      fileId
      fileName
      fileSize
      file
      displayColour
      timestamp
      presignedUrl
      fileattributesmodelSet {
        nozzleSize
      }
    }
  }
`

const GET_USERS_ORGANISATIONS_PRODUCTS = gql`
  query GetProductsForOrganisations($organisationIds: [ID], $page: Int, $pageSize: Int){
    productsForOrganisations(organisationIds: $organisationIds, page: $page, pageSize: $pageSize) {
      totalCount
      totalPages
      hasNext
      hasPrevious
      products {
        productId
        productTitle
        noOfUploadedFiles
        status
        description
        sku
        createdAt
        statusOptions
        statusSeen
        latestVersion
        lastModified
        organisations {
          organisationId
          name
        }
        productVersion {
          reference
        }
        createdBy {
            userId
            firstName
            lastName
        }
        groupId {
          groupId
          groupTitle
          defaultGroup
        }
      }
    }
  }
`;

const GET_PRODUCTS_OF_GROUP = gql`
  query GetProductsOfGroup($groupId: ID!) {
    productsOfGroup(groupId: $groupId) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      sku
      createdAt
      statusOptions
      statusSeen
      latestVersion
      lastModified
      organisations {
        organisationId
        name
      }
      productVersion {
        reference
      }
      createdBy {
        userId
      }
    }
  }
`;

const GET_MARKERS_FOR_FILE = gql`
  query MarkersForFile($fileId: ID!) {
    markersForFile(fileId: $fileId) {
      markerId
      xCoord
      yCoord
      zCoord
      message
      createdAt
      createdBy {
        userId
        firstName
        lastName
      }
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      groupId
      groupTitle
      organisations {
        organisationId
        name
      }
      defaultGroup
      createdBy {
        userId
      }
    }
  }
`;

const GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS = gql`
  query GroupsOfOrganisationsWithProducts {
    groupsOfOrganisationsWithProducts {
      groups {
        groupId
        groupTitle
      }
      products {
        productId
        productTitle
        noOfUploadedFiles
        status
        description
        sku
        createdAt
        lastModified
        latestVersion
        productVersion {
          versionId
          reference
          createdAt
          versionNumber
        }
        groupId {
          groupId
        }
        organisations {
          organisationId
          name
        }
      }
    }
  }
`;

const GET_GROUP = gql`
  query Group($groupId: ID!) {
    group(groupId: $groupId) {
      groupId
      groupTitle
      organisations {
        organisationId
        name
      }
      defaultGroup
      createdBy {
        userId
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation UpdateGroup($groupId: ID!, $name: String, $addToOrganisationId: ID, $removeFromOrganisationId: ID) {
    updateGroup(groupId: $groupId, name: $name, addToOrganisationId: $addToOrganisationId, removeFromOrganisationId: $removeFromOrganisationId) {
      success
      error
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation CreateProduct($productTitle: String!, $description: String!, $sku: String) {
    createProduct(productTitle: $productTitle, description: $description, sku: $sku) {
      error
      success
      createdProduct {
        productId
        productTitle
        noOfUploadedFiles
        status
        description
        sku
        createdAt
        productVersion {
          reference
        }
      }
    }
  }
`;

const UPLOAD_FILE_AND_PRODUCT = gql`
  mutation UploadFilesAndProduct($productTitle: String!, $files: [Upload]!, $description: String!, $groupId: Int, $sku: String) {
    uploadFilesAndProduct(productTitle: $productTitle, files: $files, description: $description, groupId: $groupId, sku: $sku) {
      errors
      success
      uploadedProduct {
          productId
          productTitle
          noOfUploadedFiles
          status
          description
          sku
          createdAt
          productVersion {
            reference
          }
          filemodelSet {
            fileId
            fileName
            fileSize
            file
            timestamp
            printable
          }
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productId: ID!, $status: String, $groupId: Int, $description: String, $organisationId: ID, $sku: String) {
    updateProduct(productId: $productId, status: $status, groupId: $groupId, description: $description, organisationId: $organisationId, sku: $sku) {
      errors
      success
    }
  }
`;

const PRODUCT_STATUS_SEEN = gql`
  mutation ProductStatusSeen($productId: ID!) {
    productStatusSeen(productId: $productId) {
        errors
        success
    }
  }
`;

const UPDATE_PRODUCT_WITH_FILES = gql`
  mutation UpdateProductWithFiles($files: [Upload]!, $productId: ID!) {
    updateProductWithFiles(files: $files, productId: $productId) {
      errors
      success
      uploadedFiles {
        fileId
        presignedUrl
      }
    }
  }
`;

const GET_PARTS_OF_PRODUCT = gql`
  query PartsForProduct($productId: ID!) {
    partsForProduct(productId: $productId) {
      partId
      partName
      sku
      files {
        fileId
        fileName
        fileSize
        presignedUrl
      }
    }
  }
`

const GET_LINKED_PARTS_OF_PRODUCT = gql`
  query LinkedPartsForProduct($productId: ID!) {
    linkedPartsForProduct(productId: $productId) {
      id
      part {
        partId
        partName
        sku
        parentProductName
        files {
          fileId
          fileName
          fileSize
          presignedUrl
        }
      }
    }
  }
`

const GET_PARTS_OF_USER = gql`
  query PartsOfUser {
    partsOfUser {
      partId
      partName
      sku
      parentProductName
      files {
        fileId
        fileName
        fileSize
        presignedUrl
      }
    }
  }
`

const CREATE_PRODUCT_PART_LINK = gql`
  mutation CreateProductPartLink($productId: ID!, $partId: ID!) {
    createProductPartLink(productId: $productId, partId: $partId) {
      success
      error
    }
  }
`

const REMOVE_PRODUCT_PART_LINK = gql`
  mutation RemoveProductPartLink($id: ID!) {
    removeProductPartLink(id: $id) {
      success
      error
    }
  }
`

const CREATE_PART_FOR_PRODUCT = gql`
  mutation CreatePart($productId: ID!, $partName: String!, $fileIds: [ID]!, $sku: String) {
    createPart(productId: $productId, partName: $partName, fileIds: $fileIds, sku: $sku) {
      success
      errors
      createdPart {
        partId
        partName
      }
    }
  }
`

const UPDATE_PART = gql`
  mutation UpdatePart($partId: ID!, $partName: String, $addFileIds: [ID], $removeFileIds: [ID], $sku: String) {
    updatePart(partId: $partId, partName: $partName, addFileIds: $addFileIds, removeFileIds: $removeFileIds, sku: $sku) {
      success
      errors
      updatedPart {
        partId
        partName
        files {
          fileId
        }
      }
    }
  }
`

const DELETE_PART = gql`
  mutation DeletePart($partId: ID!) {
    deletePart(partId: $partId) {
      success
      error
    }
  }
`

const GET_PRODUCT_PREFERENCES = gql`
  query Preferences($preferencesId: ID!) {
    preferences(preferencesId: $preferencesId) {
      preferencesId
      layerWidth
      layerHeight
      infill
      nozzleSize
      indepthPreview
      vaseMode
      vaseModeBase
      filament {
        filamentId
        colour {
          colourId
          displayName
          hexCode
        }
        material {
          materialId
          materialName
        }
      }
    }
  }
`;

const GET_FILE_ATTRIBBUTES_FOR_FILE = gql`
  query FileAttributesForFile($fileId: ID!) {
    fileAttributesForFile(fileId: $fileId) {
      printWeight
      printTime
      nozzleSize
      outOfBounds
    }
  }
`

const CREATE_PART_PREFERENCES = gql`
  mutation CreatePartPreferences($productId: ID, $partId: ID, $indepthPreview: Boolean, $infill: Float, $layerHeight: Float, $layerWidth: Float, $nozzleSize: Float, $filament: ID, $vaseMode: Boolean, $vaseModeBase: Boolean) {
    createPartPreferences(productId: $productId, partId: $partId, indepthPreview: $indepthPreview, infill: $infill, layerHeight: $layerHeight, layerWidth: $layerWidth, nozzleSize: $nozzleSize, filament: $filament, vaseMode: $vaseMode, vaseModeBase: $vaseModeBase) {
        errors
        success
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($reference: UUID!) {
      deleteProduct(reference: $reference) {
          success
      }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFile($fileId: ID!) {
      deleteFile(fileId: $fileId) {
          success
          errors
      }
  }
`;

const UPDATE_FILE_DISPLAY_COLOUR = gql`
  mutation UpdateFileDisplayColour($fileId: ID!, $displayColour: String!) {
    updateFileDisplayColour(fileId: $fileId, displayColour: $displayColour) {
      success
      error
      updatedDisplayColour
    }
  }
`

const REMOVE_FILE_FROM_PRODUCT = gql`
  mutation RemoveFileFromProduct($fileId: ID!, $productId: ID!) {
    removeFileFromProduct(fileId: $fileId, productId: $productId) {
      success
    }
  }
`

const CREATE_GROUP = gql`
  mutation CreateGroup($groupTitle: String!) {
      createGroup(groupTitle: $groupTitle) {
          success
          errors
          createdGroup {
              groupId
              groupTitle
              defaultGroup
              createdBy {
                userId
              }
          }
      }
  }
`;

const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) {
      success
      errors
    }
  }
`

const CREATE_MARKER_FOR_FILE = gql`
  mutation CreateMarker($fileId: ID!, $xCoordinate: Float!, $yCoordinate: Float!, $zCoordinate: Float!, $message: String) {
    createMarker(fileId: $fileId, xCoordinate: $xCoordinate, yCoordinate: $yCoordinate, zCoordinate: $zCoordinate, message: $message) {
      success
      errors
      newMarker {
        markerId
        xCoord
        yCoord
        zCoord
        message
        createdAt
        createdBy {
          userId
        }
      }
    }
  }
`;

const DELETE_MARKER = gql`
  mutation DeleteMarker($markerId: ID!) {
    deleteMarker(markerId: $markerId) {
      success
      errors
    }
  }
`;

const UPLOAD_PROTOTYPE = gql`
  mutation UploadPrototype($file: Upload!) {
    uploadPrototype(file: $file) {
        uploadedPrototype {
            prototypeId
            file {
              fileId
              fileName
              fileSize
              file
              timestamp
              presignedUrl
              validUntil
              printable
            }
        }
        success
        errors
    }
  }
`;


const CREATE_NEW_PRODUCT_VERSION = gql`
  mutation NewProductVersion($reference: UUID!, $files: [Upload], $carriedFiles: [ID], $versionTag: String) {
    newProductVersion(reference: $reference, files: $files, carriedFiles: $carriedFiles, versionTag: $versionTag) {
      success
      errors
      versionedProduct {
        versionId
        versionNumber
        versionTag
        productId
        createdAt
      }
    }
  }
`;


/*** VERSION RELATED ***/
const GET_VERSIONS_FOR_PRODUCT_REF = gql`
  query VersionsForProductRef($reference: UUID!) {
    versionsForProductRef(reference: $reference) {
      versionId
      versionNumber
      versionTag
      createdAt
      reference
      productId
    }
  }
`;

/*** FILE AUTOMATION ***/
const GET_FILE_AUTOMATION = gql`
  query FileAutomation($productId: ID!, $fileId: ID!) {
    fileAutomation(productId: $productId, fileId: $fileId) {
      id
      product {
        productId
      }
      file {
        fileId
      }
      zAdjust
      overwriteExtruderTemp
      overwriteBedTemp
      speedMultiplier
      knockOffType
      customRetractLength
      additionalDeretraction
      coolBedTo
      coolBedMidPrint
      skipWaitForCoolingBed
      liftPartDistance
      levelEachCycle
      centerPart
    }
  }
`;


const CREATE_FILE_AUTOMATION = gql`
  mutation CreateFileAutomation(
    $fileId: ID!,
    $productId: ID!,
    $zAdjust: Float,
    $overwriteExtruderTemp: Float,
    $overwriteBedTemp: Float,
    $speedMultiplier: Float,
    $knockOffType: String,
    $customRetractLength: Float,
    $additionalDeretraction: Float,
    $coolBedTo: Float,
    $coolBedMidPrint: Boolean,
    $skipWaitForCoolingBed: Boolean,
    $liftPartDistance: Float,
    $levelEachCycle: Boolean,
    $centerPart: Boolean
  ) {
    createFileAutomation(
      fileId: $fileId,
      productId: $productId,
      zAdjust: $zAdjust,
      overwriteExtruderTemp: $overwriteExtruderTemp,
      overwriteBedTemp: $overwriteBedTemp,
      speedMultiplier: $speedMultiplier,
      knockOffType: $knockOffType,
      customRetractLength: $customRetractLength,
      additionalDeretraction: $additionalDeretraction,
      coolBedTo: $coolBedTo,
      coolBedMidPrint: $coolBedMidPrint,
      skipWaitForCoolingBed: $skipWaitForCoolingBed,
      liftPartDistance: $liftPartDistance,
      levelEachCycle: $levelEachCycle,
      centerPart: $centerPart
    ) {
      success
      error
      fileAutomation {
        id
        zAdjust
        overwriteExtruderTemp
        overwriteBedTemp
        speedMultiplier
        knockOffType
        customRetractLength
        additionalDeretraction
        coolBedTo
        coolBedMidPrint
        skipWaitForCoolingBed
        liftPartDistance
        levelEachCycle
        centerPart
      }
    }
  }
`;


const UPDATE_FILE_AUTOMATION = gql`
  mutation UpdateFileAutomation(
    $id: ID!,
    $zAdjust: Float,
    $overwriteExtruderTemp: Float,
    $overwriteBedTemp: Float,
    $speedMultiplier: Float,
    $knockOffType: String,
    $customRetractLength: Float,
    $additionalDeretraction: Float,
    $coolBedTo: Float,
    $coolBedMidPrint: Boolean,
    $skipWaitForCoolingBed: Boolean,
    $liftPartDistance: Float,
    $levelEachCycle: Boolean,
    $centerPart: Boolean
  ) {
    updateFileAutomation(
      id: $id,
      zAdjust: $zAdjust,
      overwriteExtruderTemp: $overwriteExtruderTemp,
      overwriteBedTemp: $overwriteBedTemp,
      speedMultiplier: $speedMultiplier,
      knockOffType: $knockOffType,
      customRetractLength: $customRetractLength,
      additionalDeretraction: $additionalDeretraction,
      coolBedTo: $coolBedTo,
      coolBedMidPrint: $coolBedMidPrint,
      skipWaitForCoolingBed: $skipWaitForCoolingBed,
      liftPartDistance: $liftPartDistance,
      levelEachCycle: $levelEachCycle,
      centerPart: $centerPart
    ) {
      success
      error
      fileAutomation {
        id
        zAdjust
        overwriteExtruderTemp
        overwriteBedTemp
        speedMultiplier
        knockOffType
        customRetractLength
        additionalDeretraction
        coolBedTo
        coolBedMidPrint
        skipWaitForCoolingBed
        liftPartDistance
        levelEachCycle
        centerPart
      }
    }
  }
`;



const GET_PRODUCT_BY_REF = gql`
  query ProductByRef($reference: UUID!, $versionNumber: Int) {
    productByRef(reference: $reference, versionNumber: $versionNumber) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      sku
      createdAt
      createdBy {
        userId
        firstName
        lastName
      }
      lastModified
      latestVersion
      links {
        id
        linkName
        linkUrl
        createdAt
      }
      productVersion {
        versionId
        reference
        createdAt
        versionNumber
      }
      groupId {
        groupId
        groupTitle
        createdBy {
          userId
        }
      }
      organisations {
        organisationId
        name
      }
    }
  }
`;

const FILE_SLICER = gql`
  query FileSlicer($stlFileId: ID!) {
    fileSlicer(stlFileId: $stlFileId) {
      id
      isSlicing
      slicingError
      stl {
        fileId
      }
      gcode {
        fileId
      }
      rotateX
      rotateY
      rotateZ
      nozzleDiameter
      layerHeight
      extrusionWidth
      bottomSolidLayers
      firstLayerHeight
      speed
      fanSpeed
      temperature
    }
  }
`

const RESLICE_FILE = gql`
  mutation ResliceFile($stlFileId: ID!, $productId: ID!, $rotateX: Float, $rotateY: Float, $rotateZ: Float, $nozzleDiameter: Float, $layerHeight: Float, $extrusionWidth: Float, $bottomSolidLayers: Float, $firstLayerHeight: Float, $speed: Float, $fanSpeed: Float, $temperature: Float) {
    resliceFile(stlFileId: $stlFileId, productId: $productId, rotateX: $rotateX, rotateY: $rotateY, rotateZ: $rotateZ, nozzleDiameter: $nozzleDiameter, layerHeight: $layerHeight, extrusionWidth: $extrusionWidth, bottomSolidLayers: $bottomSolidLayers, firstLayerHeight: $firstLayerHeight, speed: $speed, fanSpeed: $fanSpeed, temperature: $temperature) {
      success
      error 
    }
  }
`

const GET_EMISSIONS_FOR_FILE = gql`
  query EmissionsForGcode($fileId: ID!, $filamentId: ID!, $factoryId: ID!, $quantity: Int!) {
    emissionsForGcode(fileId: $fileId, filamentId: $filamentId, factoryId: $factoryId, quantity: $quantity) {
      total
      material
      materialTransport
      printing
    }
  }
`

const CREATE_LINK = gql`
  mutation CreateLink($linkName: String!, $linkUrl: String!, $productId: ID!) {
    createLink(linkName: $linkName, linkUrl: $linkUrl, productId: $productId) {
      success
      error
    }
  }
`

const DELETE_LINK = gql`
  mutation DeleteLink($id: ID!) {
    deleteLink(id: $id) {
      success
      error
    }
  }
`

const DELETE_FILE_AUTOMATION = gql`
  mutation DeleteFileAutomation($id: ID!) {
    deleteFileAutomation(id: $id) {
      success
      error
    }
  }
`

const products = {
  queries: {
    GET_PRODUCTS,
    GET_PRODUCTS_FOR_USER,
    GET_USERS_ORGANISATIONS_PRODUCTS,
    GET_PRODUCT_BY_REF,
    GET_VERSIONS_FOR_PRODUCT_REF,
    GET_GROUPS,
    GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS,
    GET_GROUP,
    GET_PRODUCTS_OF_GROUP,
    GET_MARKERS_FOR_FILE,
    GET_PRODUCT_PREFERENCES,
    FILE_SLICER,
    GET_EMISSIONS_FOR_FILE,
    GET_FILE_ATTRIBBUTES_FOR_FILE,
    GET_PARTS_OF_PRODUCT,
    GET_LINKED_PARTS_OF_PRODUCT,
    GET_PARTS_OF_USER,
    GET_FILE_AUTOMATION,
    GET_FILES_FOR_PRODUCT
  },
  mutations: {
    CREATE_PRODUCT,
    UPLOAD_FILE_AND_PRODUCT,
    UPDATE_PRODUCT,
    PRODUCT_STATUS_SEEN,
    UPDATE_PRODUCT_WITH_FILES,
    DELETE_PRODUCT,
    UPDATE_FILE_DISPLAY_COLOUR,
    DELETE_FILE,
    CREATE_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP,
    CREATE_MARKER_FOR_FILE,
    DELETE_MARKER,
    UPLOAD_PROTOTYPE,
    CREATE_NEW_PRODUCT_VERSION,
    CREATE_PART_PREFERENCES,
    RESLICE_FILE,
    CREATE_PART_FOR_PRODUCT,
    DELETE_PART,
    UPDATE_PART,
    CREATE_PRODUCT_PART_LINK,
    REMOVE_PRODUCT_PART_LINK,
    REMOVE_FILE_FROM_PRODUCT,
    CREATE_LINK,
    DELETE_LINK,
    CREATE_FILE_AUTOMATION,
    UPDATE_FILE_AUTOMATION,
    DELETE_FILE_AUTOMATION
  } 
}

/*****************************************************************
                          ORGANISATIONS                               
*****************************************************************/

const GET_ORGANISATIONS = gql`
  query Organisations {
    organisations {
      organisationId
      name
      size
      productCount
      createdBy {
        userId
        username
        email
        firstName
        lastName
      }
      memberships {
        membershipId
      }
     }
  }
`

const GET_ORGANISATIONS_IN_DETAIL = gql`
  query Organisations {
    organisations {
      organisationId
      name
      size
      productCount
      createdBy {
        userId
        username
        email
        firstName
        lastName
      }
      memberships {
        membershipId
        role {
          name
          level
        }
        user {
          userId
          username
          firstName
          lastName
        }
      }
     }
  }
`

const GET_ORGANISATIONS_FOR_PRODUCT = gql`
  query OrganisationsForProduct($productId: ID!) {
    organisationsForProduct(productId: $productId) {
      organisations {
        organisationId
        name
        size
        productCount
        createdBy {
          userId
          username
          email
          firstName
          lastName
        }
        memberships {
          membershipId
          role {
            name
            level
          }
          user {
            userId
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`

const GET_ORGANISATIONS_FOR_GROUP = gql`
  query OrganisationsForGroup($groupId: ID!) {
    organisationsForGroup(groupId: $groupId) {
      organisations {
        organisationId
        name
        size
        productCount
        createdBy {
          userId
          username
          email
          firstName
          lastName
        }
        memberships {
          membershipId
          role {
            name
            level
          }
          user {
            userId
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`

const CREATE_ORGANISATION = gql`
  mutation CreateOrganisation($name: String!, $contactEmail: String!) {
    createOrganisation(name: $name, contactEmail: $contactEmail) {
      success
      error
      createdOrganisation {
        organisationId
        name
      }
    }
  }
`

const ADD_USER_TO_ORGANISATION = gql`
  mutation AddUserToOrg($organisationId: ID!, $userId: ID!, $roleName: String!) {
    updateOrganisation(organisationId: $organisationId, userId: $userId, roleName: $roleName) {
      success
      error
      updatedOrganisation {
        organisationId
        name
        memberships {
          membershipId
          user {
            userId
          }
        }
      }
    }
  }
`

const ADD_USER_BY_EMAIL_TO_ORGANISATION = gql`
  mutation AddUserToOrg($organisationId: ID!, $userEmail: String!, $roleName: String!) {
    updateOrganisation(organisationId: $organisationId, userEmail: $userEmail, roleName: $roleName) {
      success
      error
      updatedOrganisation {
        organisationId
        name
        memberships {
          membershipId
          user {
            userId
          }
        }
      }
    }
  }
`

const REMOVE_USER_FROM_ORGANISATION = gql`
  mutation RemoveUserFromOrg($organisationId: ID!, $userId: ID!) {
    updateOrganisation(organisationId: $organisationId, userId: $userId) {
      success
      error
    }
  }
`

const organisations = {
  queries: {
    GET_ORGANISATIONS,
    GET_ORGANISATIONS_IN_DETAIL,
    GET_ORGANISATIONS_FOR_PRODUCT,
    GET_ORGANISATIONS_FOR_GROUP
  },
  mutations: {
    CREATE_ORGANISATION,
    ADD_USER_TO_ORGANISATION,
    ADD_USER_BY_EMAIL_TO_ORGANISATION,
    REMOVE_USER_FROM_ORGANISATION
  }
}


/*****************************************************************
                        PRINT REQUESTS                               
*****************************************************************/

const CREATE_PRINT_REQUEST = gql`
  mutation PrintRequest($fileId: ID!, $filamentId: ID!, $factoryId: ID!, $quantity: Int!, $productId: ID!) {
    printRequest(fileId: $fileId, filamentId: $filamentId, factoryId: $factoryId, quantity: $quantity, productId: $productId, emailNotifications: false) {
      success
      errors
      printRequest {
        requestId
      }
    }
  }
`;

const DELETE_PRINT_REQUEST = gql`
  mutation DeletePrintRequest($requestId: ID!) {
    deletePrintRequest(requestId: $requestId) {
      success
      errors
    }
  }
`;

const UPDATE_PRODUCTION_ON_PRINT_REQUEST = gql`
  mutation UpdateProductionOnPrintRequest($requestId: ID!, $addAmount: Int!) {
    updateProductionOnPrintRequest(requestId: $requestId, addAmount: $addAmount) {
      success
      error
    }
  }
`

// This will trigger the Scheduler to schedule the request onto an actual printer
const OPTIMIZE_SCHEDULE_FROM_REQUEST = gql`
  mutation OptimizeScheduleFromRequest($requestId: ID!) {
    optimizeScheduleFromRequest(requestId: $requestId) {
      success
      error
    }
  }
`;

const GET_PRINT_REQUESTS_FOR_USER = gql`
  query UserPrintRequests {
    userPrintRequests {
      requestId
      quantity
      produced
      interupted
      issuedAt
      factory {
        factoryId
        name
      }
      file {
        fileId
        fileName
        fileSize
        timestamp
        presignedUrl
        validUntil
        printable
      }
    }
  }
`;

const GET_PRINT_REQUESTS_FOR_PRODUCT = gql`
  query ProductPrintRequests($productId: ID!) {
    productPrintRequests(productId: $productId) {
      requestId
      quantity
      produced
      interupted
      issuedAt
      requestedBy {
        userId
        firstName
      }
      factory {
        factoryId
        name
      }
      file {
        fileId
        fileName
        fileSize
        timestamp
        presignedUrl
        validUntil
        printable
      }
    }
  }
`;

const GET_ALL_PRINT_REQUESTS = gql`
  query PrintRequests {
    printRequests {
      requestId
      quantity
      produced
      interupted
      issuedAt
      requestedBy {
        userId
        firstName
      }
      file {
        fileId
        fileName
        fileSize
        presignedUrl
      }
      relatedProduct {
        productId
        productVersion {
          reference
        }
      }
      filament {
        filamentId
        colour {
          displayName
          hexCode
        }
        material {
          displayName
        }
      }
    }
  }
`;

const printRequests = {
  queries: {
    GET_PRINT_REQUESTS_FOR_USER,
    GET_PRINT_REQUESTS_FOR_PRODUCT,
    GET_ALL_PRINT_REQUESTS,
  },
  mutations: {
    CREATE_PRINT_REQUEST,
    DELETE_PRINT_REQUEST,
    UPDATE_PRODUCTION_ON_PRINT_REQUEST,
    OPTIMIZE_SCHEDULE_FROM_REQUEST
  }
}

/*****************************************************************
                             MESSAGES                               
*****************************************************************/

const GET_MESSAGES_FOR_PRODUCT = gql`
  query MessagesForProduct($productReference: UUID!) {
    messagesForProduct(productReference: $productReference) {
      messageId
      message
      datetime
      read
      file {
        fileName
        fileSize
        presignedUrl
      }
      sender {
        userId
        username
      }      
    }
  }
`;

const ADD_MESSAGE_TO_PRODUCT = gql`
  mutation uploadMessage($productId: ID!, $message: String!, $emailNotifications: Boolean) {
    uploadMessage(productId: $productId, message: $message, emailNotifications: $emailNotifications) {
        success
        errors
    }
  }
`;

const ADD_MESSAGE_WITH_FILE_TO_PRODUCT = gql`
  mutation UploadMessageWithAttachment($productId: ID!, $message: String!, $file: Upload!, $emailNotifications: Boolean) {
    uploadMessageWithAttachment(productId: $productId, message: $message, file: $file, emailNotifications: $emailNotifications) {
      success
      errors
    }
  }
`;

const MARK_MESSAGE_AS_READ = gql`
  mutation readMessage($messageId: ID!) {
      readMessage(messageId: $messageId) {
          success
          errors
      }
  }
`;

const messages = {
  queries: {
    GET_MESSAGES_FOR_PRODUCT,
  },
  mutations: {
    ADD_MESSAGE_TO_PRODUCT,
    ADD_MESSAGE_WITH_FILE_TO_PRODUCT,
    MARK_MESSAGE_AS_READ,
  }
}

/*****************************************************************/
/*                          PRINTERS                             */
/*****************************************************************/

const GET_ALL_PRINTERS_FOR_FACTORY_ID = gql`
  query PrintersInLocation($factoryId: ID!) {
    printersInLocation(factoryId: $factoryId) {
      printerId
      name
      displayNumber
      status
      bedTemperature
      nozzleTemperature
      bedTemperatureTarget
      nozzleTemperatureTarget
      ipAddress
      assigned
      loadedNozzle
      schedulePaused
      technicianReview
      uploadingFileProgress
      activeFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
      }
      inactiveFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
      }
    }
  }
`;




const GET_PRINTER = gql`
  query Printer($printerId: ID!) {
    printer(printerId: $printerId) {
      printerId
      name
      displayNumber
      status
      bedTemperature
      nozzleTemperature
      bedTemperatureTarget
      nozzleTemperatureTarget
      ipAddress
      assigned
      schedulePaused
      technicianReview
      uploadingFileProgress
      bedClear
      factory {
        factoryId
      }
      loadedNozzle
      activeFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
        material {
          materialId
          displayName
          materialName
        }
      }
      inactiveFilament {
        filamentId
        colour {
          colourId
          hexCode
          displayName
        }
        material {
          materialId
          displayName
          materialName
        }
      }
    }
  }
`

const GET_AVAILABLE_FILAMENTS = gql`
  query Filament {
    filament {
      filamentId
      stock
      colour {
          colourId
          displayName
          hexCode
      }
      material {
          materialId
          displayName
          materialName
          materialCost
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const CLEAR_SCHEDULE_FOR_PRINTER = gql`
  mutation ClearScheduleForPrinter($printerId: ID!) {
    clearScheduleForPrinter(printerId: $printerId) {
      success
      error
    }
  }
`;

const REOPTIMISE_SCHEDULE_FOR_PRINTERS = gql`
  mutation ReoptimiseScheduleForPrinter($printerId: ID!) {
    reoptimiseScheduleForPrinter(printerId: $printerId) {
      success
      error
    }
  }
`;

const UPDATE_ACTION = gql`
  mutation UpdateAction($actionId: ID!) {
    updateAction(actionId: $actionId, completed: true) {
      success
      error
    }
  }
`;

const SEND_COMMAND_FOR_PRINTER = gql`
  mutation SendCommandForPrinter($printerId: ID!, $command: String!, $commandContent: String) {
    sendCommandForPrinter(printerId: $printerId, command: $command, commandContent: $commandContent) {
      success
      error
    }
  }
`

const CREATE_PRINTER = gql`
  mutation CreatePrinter($name: String!, $uuid: String!, $factoryId: ID!) {
    createPrinter(name: $name, uuid: $uuid, factoryId: $factoryId) {
      success
      error
      createdPrinter {
        printerId
        uuid
        name
        factory {
          factoryId
        }
      }
    }
  }
`;

const DELETE_PRINTER = gql`
  mutation DeletePrinter($printerId: ID!) {
    deletePrinter(printerId: $printerId) {
      success
      error
    }
  }
`

const UPDATE_PRINTER = gql`
  mutation UpdatePrinter($printerId: ID!, $schedulePaused: Boolean, $activeFilamentId: ID, $inactiveFilamentId: ID, $loadedNozzle: Float) {
    updatePrinter(printerId: $printerId, schedulePaused: $schedulePaused, activeFilamentId: $activeFilamentId, inactiveFilamentId: $inactiveFilamentId, loadedNozzle: $loadedNozzle) {
      success
      error
      updatedPrinter {
        printerId
        name
        displayNumber
        status
        bedTemperature
        nozzleTemperature
        ipAddress
        schedulePaused
        technicianReview
        assigned
        factory {
          factoryId
        }
        loadedNozzle
        activeFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
        inactiveFilament {
          filamentId
          colour {
            colourId
            hexCode
            displayName
          }
          material {
            materialId
            displayName
            materialName
          }
        }
      }
    }
  }
`;

const RESET_PRINTER = gql`
  mutation ResetForPrinter($printerId: ID!) {
      resetForPrinter(printerId: $printerId) {
          success
          error
      }
  }
`;

const PRINT_FILE_ON_PRINTER = gql`
  mutation PrintFileOnPrinter($printerId: ID!, $file: Upload!) {
    printFileOnPrinter(printerId: $printerId, file: $file) {
      success
      error
    }
  }
`

const ISSUE_PRINT_TO_PRINTERS = gql`
  mutation IssuePrintToPrinters($printerIds: [ID]!, $fileId: ID!, $quantity: Int!, $prId: ID!) {
    issuePrintToPrinters(printerIds: $printerIds, fileId: $fileId, quantity: $quantity, prId: $prId) {
      success
      error
      fileUrl
      fileName
    }
  }
`

const AUTOMATED_GCODE_FILE = gql`
  mutation AutomatedGcodeFile($fileId: ID!, $quantity: Int!, $prId: ID, $productId: ID) {
    automatedGcodeFile(fileId: $fileId, quantity: $quantity, prId: $prId, productId: $productId) {
      success
      error
      file {
        fileName
        presignedUrl
      }
    }
  }
`

const CANCEL_PRINT_FOR_PRINTER = gql`
  mutation CancelPrintForPrinter($printerId: ID!, $printJobId: ID) {
    cancelPrintForPrinter(printerId: $printerId, printJobId: $printJobId) {
        success
        error
    }
  }
`;

const CONNECT_PRINTER = gql`
  mutation ConnectPrinter($printerId: ID!) {
    connectPrinter(printerId: $printerId) {
      success
      error
    }
  }
`

const PAUSE_PRINT_FOR_PRINTER = gql`
  mutation PausePrintForPrinter($printerId: ID!) {
    pausePrintForPrinter(printerId: $printerId) {
        success
        error
    }
  }
`;

const RESUME_PRINT_FOR_PRINTER = gql`
  mutation ResumePrintForPrinter($printerId: ID!) {
    resumePrintForPrinter(printerId: $printerId) {
        success
        error
    }
  }
`;

const HEAT_PRINTERS = gql`
  mutation HeatPrinters($printerIds: [ID]!) {
    heatPrinters(printerIds: $printerIds) {
        success
        error
    }
  }
`;

const COOL_PRINTERS = gql`
  mutation CoolPrinters($printerIds: [ID]!) {
    coolPrinters(printerIds: $printerIds) {
        success
        error
    }
  }
`;


const GET_PRINT_STATUS_FOR_FILE = gql`
  query PrintStatusForFile($fileId: ID!) {
    printStatusForFile(fileId: $fileId) {
      printStatusId
      percentage
      printTimeDone
      printTimeLeft
      completed
      started
      issuedAt
      completedAt
    }
  }
`;

const GET_PRINTER_STATUS_FOR_FACTORY = gql`
  query PrintersInLocation($factoryId: ID!) {
    printersInLocation(factoryId: $factoryId) {
      printerId
      name
      displayNumber
      status
    }
  }
`;

const GET_ACTIVE_PRINT_JOB_FOR_PRINTER_ID = gql`
  query ActivePrintJobForPrinterId($printerId: ID!) {
    activePrintJobForPrinterId(printerId: $printerId) {
      id
      file {
        fileName
      }
      progress
      printTime
      printTimeLeft
      quantity
      completed
      cancelled
    }
  }
`

const GET_ACTIVE_PRINT_JOB_FOR_PRINTER_IDS = gql`
  query ActivePrintJobsForPrinterIds($printerIds: [ID!]) {
    activePrintJobsForPrinterIds(printerIds: $printerIds) {
      id
      file {
        fileName
      }
      printer {
        printerId
      }
      progress
      printTime
      printTimeLeft
      quantity
      completed
      cancelled
    }
  }
`

const GET_PRINT_JOBS_FOR_PR_IDS = gql`
  query printJobsForPrIds($prIds: [ID]!, $historySize: Int) {
    printJobsForPrIds(prIds: $prIds, historySize: $historySize) {
      id
      relatedPrintRequest {
        requestId
      }
      printer {
        printerId
        name
        status
      }
      createdAt
      createdBy {
        userId
        firstName
      }
      progress
      quantity
      started
      completed
      cancelled
    }
  }
`

const GET_FINISHED_PRINT_JOBS_FOR_PRINTER_ID = gql`
  query FinishedPrintJobsForPrinterId($printerId: ID!) {
    finishedPrintJobsForPrinterId(printerId: $printerId) {
      id
      file {
        fileName
      }
      createdAt
      createdBy {
        firstName
      }
      progress
      printTime
      printTimeLeft
      quantity
      completed
      cancelled
    }
  }
`

const GET_PRINT_JOBS_FOR_PRINTER_ID = gql`
  query printJobsForPrinterId($printerId: ID!, $count: Int!) {
    printJobsForPrinterId(printerId: $printerId, count: $count) {
      id
      file {
        fileName
      }
      createdAt
      createdBy {
        firstName
      }
      progress
      printTime
      printTimeLeft
      quantity
      started
      completed
      cancelled
    }
  }
`

const GET_PRINT_JOBS_FOR_PRINTER_IDS = gql`
  query printJobsForPrinterIds($printerIds: [ID!], $historySize: Int) {
    printJobsForPrinterIds(printerIds: $printerIds, historySize: $historySize) {
      id
      file {
        fileName
      }
      createdAt
      createdBy {
        firstName
      }
      printer {
        printerId
        name
      }
      dropPrint
      progress
      printTime
      printTimeLeft
      quantity
      started
      completed
      cancelled
    }
  }
`

const printers = {
  queries: {
    GET_ALL_PRINTERS_FOR_FACTORY_ID,
    GET_PRINTER,
    GET_AVAILABLE_FILAMENTS,
    GET_PRINT_STATUS_FOR_FILE,
    GET_PRINTER_STATUS_FOR_FACTORY,
    GET_ACTIVE_PRINT_JOB_FOR_PRINTER_ID,
    GET_ACTIVE_PRINT_JOB_FOR_PRINTER_IDS,
    GET_PRINT_JOBS_FOR_PR_IDS,
    GET_FINISHED_PRINT_JOBS_FOR_PRINTER_ID,
    GET_PRINT_JOBS_FOR_PRINTER_ID,
    GET_PRINT_JOBS_FOR_PRINTER_IDS
  },
  mutations: {
    CLEAR_SCHEDULE_FOR_PRINTER,
    REOPTIMISE_SCHEDULE_FOR_PRINTERS,
    UPDATE_ACTION,
    CREATE_PRINTER,
    DELETE_PRINTER,
    UPDATE_PRINTER,
    SEND_COMMAND_FOR_PRINTER,
    PRINT_FILE_ON_PRINTER,
    ISSUE_PRINT_TO_PRINTERS,
    AUTOMATED_GCODE_FILE,
    CANCEL_PRINT_FOR_PRINTER,
    CONNECT_PRINTER,
    PAUSE_PRINT_FOR_PRINTER,
    RESUME_PRINT_FOR_PRINTER,
    HEAT_PRINTERS,
    COOL_PRINTERS,
    RESET_PRINTER
  }
}



/*****************************************************************/
/*                          MACROS                               */
/*****************************************************************/

const GET_ALL_MACROS = gql`
  query AllMacros {
    allMacros {
      macroId
      command
    }
  }
`;

const CREATE_NEW_MACRO = gql`
  mutation CreateMacro($command: String!, $macro: Upload!) {
    createMacro(command: $command, macro: $macro) {
      success
      error
      addedMacro {
        macroId
        command
      }
    }
  }
`;

const SEND_MACRO_TO_PRINTER = gql`
  mutation SendMacroForPrinter($command: String!, $printerId: ID!) {
    sendMacroForPrinter(command: $command, printerId: $printerId) {
      success
      error
    }
  }
`;

const macros = {
  queries: {
    GET_ALL_MACROS,
  },
  mutations: {
    CREATE_NEW_MACRO,
    SEND_MACRO_TO_PRINTER
  }
}


/*****************************************************************
                             USER                               
*****************************************************************/

const ALL_USERS = gql`
  query AllUsers {
    allUsers {
      lastLogin
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      dateJoined
      userId
      email
      username
    }
  }
`;

const USERNAMES = gql`
  query Usernames($username: String!) {
    usernames(username: $username) {
      userId
      username
    }
  }
`

const USER_BY_EMAIL = gql`
  query UserByEmail($email: String!) {
    userByEmail(email: $email) {
      userId
      firstName
      lastName
    }
  }
`


const ME = gql`
  query Me {
    me {
      lastLogin
      firstName
      lastName
      isStaff
      isSuperuser
      isActive
      dateJoined
      userId
      email
      username
      id
      pk
      archived
      verified
      secondaryEmail
      userpreferencesmodelSet {
        reviewerMode
        sortHomeByProjects
        productsViewType
        previewGcode
      }
    }
  }
`;

const GET_USER_PREFERENCES = gql`
  query UserPreferences {
    userPreferences {
      id
      user {
        userId
      }
      reviewerMode
      sortHomeByProjects
      productsViewType
      previewGcode
    }
  }
`;

const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email:$email, password:$password)
    {
        token
        success
        user {
          userId
          username
          isStaff
          isSuperuser   
          firstName
          lastName
          verified
          userpreferencesmodelSet {
            reviewerMode
            sortHomeByProjects
            productsViewType
            previewGcode
          }
        }
    }
  }
`;

const REGISTER = gql`
  mutation Register($email: String!, $password1: String!, $password2: String!, $username: String!, $firstName: String!, $lastName: String!) {
    registerAccount(email:$email, password1:$password1, password2:$password2, username: $username, firstName: $firstName, lastName: $lastName) {
      success
      token
      errors
      user {
        userId
        username
        isStaff
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_MY_ACCOUNT = gql`
  mutation UpdateAccount($firstName: String, $lastName: String, $email: String, $username: String) {
    updateAccount(firstName: $firstName, lastName: $lastName, email: $email, username: $username) {
      success
      errors
    }
  }
`;


const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      # payload
      success
      errors
    }
  }
`;

const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation PasswordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
    }
  }
`;

const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($reviewerMode: Boolean, $sortHomeByProjects: Boolean, $productsViewType: String, $previewGcode: Boolean) {
    updateUserPreferences(reviewerMode: $reviewerMode, sortHomeByProjects: $sortHomeByProjects, productsViewType: $productsViewType, previewGcode: $previewGcode) {
      success
      errors
      updatedUserPreferences {
        id
        user {
          userId
        }
        reviewerMode
        sortHomeByProjects
        productsViewType
        previewGcode
      }
    }
  }
`

function setToken(token: string) {
  const expires = new Date(Date.now() + 7 * 864e5).toUTCString(); // Expires in 7 days
  document.cookie = LOCAL ?
    `token=${encodeURIComponent(token)}; expires=${expires}; path=/; Secure; SameSite=Lax`
    : `token=${encodeURIComponent(token)}; expires=${expires}; path=/; domain=.batch.works; Secure; SameSite=Lax`
}

function getToken() {
  const name = 'token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (let cookie of cookiesArray) {
    cookie = cookie.trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }
  return '';
}

function isTokenSet() {
  return getToken() !== '';
}

function removeToken() {
  document.cookie = LOCAL ? 
    'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Lax'
    : 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.batch.works; Secure; SameSite=Lax'
}

const user = {
  queries: {
    ME,
    ALL_USERS,
    GET_USER_PREFERENCES,
    USERNAMES,
    USER_BY_EMAIL
  },
  mutations: {
    REGISTER,
    UPDATE_MY_ACCOUNT,
    LOGIN,
    VERIFY_TOKEN,
    VERIFY_ACCOUNT,
    SEND_PASSWORD_RESET_EMAIL,
    RESET_PASSWORD,
    UPDATE_USER_PREFERENCES,
  },
  local: {
    setToken,
    isTokenSet,
    getToken,
    removeToken,
  }
}

const api = {
  PING,
  GRAPHQL_URI,
  user,
  products,
  organisations,
  messages,
  printers,
  macros,
  printRequests,
}

export default api;