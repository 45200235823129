import usePrintersStore from '@/context/usePrintersStore'
import api from '@/global-components/api'
import helpers from '@/global-components/components/helpers'
import { Button } from '@/global-components/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/global-components/components/ui/dialog'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/global-components/components/ui/table'
import { PrintJob } from '@/global-components/types'
import { useQuery } from '@apollo/client'
import { History, Loader } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import './PrintJobHistory.scss'

interface PrintJobHistoryProps {

}

const PrintJobHistory: React.FC<PrintJobHistoryProps> = ({}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [historySize, setHistorySize] = useState<number>(50)
  const [lastUpdated, setLastUpdated] = useState<string | undefined>(undefined);
  const { printers } = usePrintersStore()

  const printJobsQuery = useQuery(api.printers.queries.GET_PRINT_JOBS_FOR_PRINTER_IDS, {
    variables: {
      printerIds: printers.map(printer => printer.printerId),
      historySize: historySize
    },
    notifyOnNetworkStatusChange: true,
    skip: !open || !printers || printers.length === 0
  })

  useEffect(() => {
    if (printJobsQuery.loading || printJobsQuery.networkStatus === 4) {
      setLastUpdated(new Date().toLocaleString())
    }
  }, [printJobsQuery.loading, printJobsQuery.networkStatus]);

  return (
    <div className='print-job-history-overlay'>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button
            variant='bwprimary'
            size='sm'
            className="flex justify-center gap-1 items-center group">
              <History className='h-4 w-4'/> Print job history
          </Button>
        </DialogTrigger>
        <DialogContent className='max-w-[90vw] overflow-y-auto bg-bw-background-grey'>
          <DialogHeader>
            <DialogTitle className='flex items-center justify-between gap-1'>
              <div className='flex items-center gap-1'>
                <History className='h-4 w-4 min-w-4 min-h-4'/> Last {historySize} print jobs
              </div>
              <div className='flex items-center gap-1'>
                <Button variant='link' loading={printJobsQuery.networkStatus === 4} onClick={() => printJobsQuery.refetch()}>
                  Reload
                </Button>
                {lastUpdated !== undefined && 
                  <div className='font-normal opacity-30'>Last updated: {lastUpdated}</div>
                }
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className='print-job-history relative max-h-[80vh] overflow-y-scroll'>
            {(printJobsQuery.loading && printJobsQuery.networkStatus !== 4)
              ? <div className='flex items-center justify-center gap-1 text-sm py-24'>
                  <Loader className='h-4 w-4 animate-spin' />
                  Loading history ...
                </div>
              : <div className='print-job'>
                  <Table className='relative max-w-full bg-transparent'>
                    <TableHeader className="relative text-xs text-bw-green hover:bg-transparent">
                      <TableRow className="hover:bg-transparent">
                        <TableHead>ID</TableHead> 
                        <TableHead>Quantity</TableHead>
                        <TableHead>File name</TableHead>
                        <TableHead>Drop print</TableHead>
                        <TableHead>Printer</TableHead>
                        <TableHead>Created by</TableHead>
                        <TableHead className='text-right'>Print duration</TableHead>
                        <TableHead className='text-right'>Created at</TableHead>
                        <TableHead className='text-right'>Success</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody className='bg-transparent'>
                      {printJobsQuery.data?.printJobsForPrinterIds?.map((printJob:PrintJob, index:number) => {
                        return (
                          <TableRow key={index} className='bg-transparent'>
                            <TableCell className='p-4'><span className='opacity-30'>{printJob.id}</span></TableCell>
                            <TableCell>{printJob.quantity}</TableCell>
                            <TableCell className='font-medium'>{printJob.file?.fileName}</TableCell>
                            <TableCell>{printJob.dropPrint ? 'Yes' : <span className='opacity-30'>No</span>}</TableCell>
                            <TableCell>{printJob.printer?.name}</TableCell>
                            <TableCell>{printJob.createdBy?.firstName}</TableCell>
                            <TableCell className='text-right'>{helpers.formatSecondsIntoHHMMSS(Number(printJob.printTime))}</TableCell>
                            <TableCell className='text-right'>{helpers.formatDateToDDMMYY(printJob.createdAt, false, true, true)}</TableCell>
                            <TableCell>
                              <span className='text-right flex justify-end'>
                              {(printJob.completed && !printJob.cancelled) && <div>&#x2705;</div>}
                              {(printJob.cancelled && !printJob.completed) && <div>&#x274C;</div>}
                              {(printJob.started && !printJob.cancelled && !printJob.completed) 
                                && <Loader className='h-4 w-4 min-w-4 min-h-4 animate-spin' />}
                              </span>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </div>

            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default PrintJobHistory