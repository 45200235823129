import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../context/auth';
import { userType } from '../../global-components/types'
import { useApolloClient, useMutation } from '@apollo/client';
import { Loader, RefreshCw } from 'lucide-react'
import './Home.scss';
import Printers from '../../components/printers/Printers'
import PrintQueue from '../../components/printQueue/PrintQueue'
import UploadMacro from '../../components/uploadMacro/UploadMacro'
import BwSymbol from '@/global-components/brand-assets/BwSymbol';
import { User } from '@/global-components/types'
import { Button } from "@/global-components/components/ui/button"

import { Input } from '@/global-components/components/ui/input'

import api from '@/global-components/api'

import { Switch } from "@/global-components/components/ui/switch"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/global-components/components/ui/tabs"
import { Grip, List } from "lucide-react";
import PrintRequests from '@/components/printRequests/PrintRequests';
import helpers from '@/global-components/components/helpers';
import StatusBar from '@/components/statusBar/StatusBar';

const Home = () =>  {
  const { setLoggedIn, loggedIn } = useAuth();
  const user: User | null = useAuth().user;
  const client = useApolloClient();

  const logOut = () => {
    setLoggedIn(false);
    api.user.local.removeToken();
    client.clearStore();
    window.location.reload();
  } 
  
  if (!user) {
    return <div className='flex flex-col items-center justify-center w-full h-full'>
      <Loader className='h-4 w-4 animate-spin' />
    </div>
  }

  return (
    <div className='view home pb-16'>
      {/* <div className='beta-tag absolute w-full flex items-center justify-center p-2 right-0 bottom-0 bg-bw-background-grey/70 text-xs text-bw-green/30 flex items-center gap-1'>
        <Button variant='ghost' size='sm' onClick={() => window.location.reload()}><RefreshCw className='h-4 w-4 font-bold'/></Button>
        <div className='flex items-center gap-1 ml-2' onClick={logOut}>
          <BwSymbol className='h-4 w-4 opacity-30' small/> 
          <b>FACTORY</b> <i>Beta</i>
        </div>
      </div> */}
      <StatusBar />
      <Printers />
      {/* <div className='h-[1px] w-full px-4'>
        <div className='bg-bw-green/10 w-full h-full'></div>
      </div> */}
      {!helpers.isMobile() && <PrintRequests />}
    </div>
  )
}

export default Home;