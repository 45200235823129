import { useEffect, useState } from 'react'
import Filament from '@/global-components/components/bw/filament/Filament'
import ProgressBar from '@/global-components/components/bw/progressBar/ProgressBar'
import './Printer.scss'
import PrinterDetail from './PrinterDetail'
import { AlertCircle, XCircle, UploadCloud, Loader, HardDrive, ZapOff, Ban, LoaderCircle, PauseCircle } from 'lucide-react'
import { Button } from '@/global-components/components/ui/button'
import api from '@/global-components/api'
import { useMutation, useQuery } from '@apollo/client'
import { PrinterType, PrintJob, PrintStatus } from '@/global-components/types'
import PrinterStatusIndicator from '@/global-components/components/bw/PrinterStatusIndicator'
import helpers from '@/global-components/components/helpers'
import { toast } from '@/global-components/components/ui/use-toast'
interface PrinterProps {
  printer: PrinterType
  printJob: PrintJob | undefined
}
const Printer: React.FC<PrinterProps> = ({ printer, printJob }) => {
  const [progress, setProgress] = useState<number>(0.0)
  const [timeLeft, setTimeLeft] = useState<number>(0.0)
  const [printerDetailsOpen, setPrinterDetailsOpen] = useState<boolean>(false)
  const [toggledPrinterId, setToggledPrinterId] = useState<string | undefined>(undefined)
  const [dragOver, setDragOver] = useState<boolean>(false)
  const [sendingFile, setSendingFile] = useState<boolean>(false)

  const [printFileOnPrinterMutation] = useMutation(api.printers.mutations.PRINT_FILE_ON_PRINTER)
  useEffect(() => {
    if (printJob) {
      const rawProgress = printJob.progress || 0
      const formattedProgress = Number(rawProgress.toFixed(2))
      setProgress(formattedProgress)
      setTimeLeft(printJob.printTimeLeft || 0)
    }
  }, [printJob])

  const [deletePrinterMutation] = useMutation(
    api.printers.mutations.DELETE_PRINTER
  )

  const deletePrinter = () => {
    deletePrinterMutation({ variables: { printerId: printer.printerId } })
      .then((result: any) => console.log(result))
      .catch((error) => console.log(error))
  }

  const openPrinterDetails = () => {
    if (!printerDetailsOpen) {
      setToggledPrinterId(printer.printerId)
      setPrinterDetailsOpen(true)
    }
  }

  // --- Drag and Drop Handlers ---
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (printer.status.toLowerCase() !== 'operational') {
      return
    }
    setDragOver(true)
  }

  const handleDragLeave = () => setDragOver(false)

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (printer.status.toLowerCase() !== 'operational') {
      return
    }
    setDragOver(false)

    const file = event.dataTransfer.files[0]
    if (file && file.name.endsWith('.gcode')) {
      handleFileUpload(file)
    } else {
      alert('Only .gcode files are allowed')
    }
  }

  // --- File Input Handler ---
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file && file.name.endsWith('.gcode')) {
      handleFileUpload(file)
    } else {
      alert('Only .gcode files are allowed')
    }
  }

  // --- File Upload Function ---
  const handleFileUpload = (file: File) => {
    console.log('Uploading file:', file.name)
    setSendingFile(true)
    
    printFileOnPrinterMutation({variables: {
      printerId: printer.printerId,
      file: file
    }})
      .then((result: any) => {
        setSendingFile(false)
        if (result.data?.printFileOnPrinter.success) {
          toast({
            title: "File sent to printer",
            variant: 'success'
          })
        } else {
          console.log(result.data?.issuePrintToPrinter.error)
          toast({
            title: "Error sending file to printer",
            variant: 'destructive'
          })
        }
      })
      .catch((error: any) => {
        console.log('send file error: ', error)
        setSendingFile(false)
      })
  }

  return (
    <div
      className={`printer relative aspect-[5/4] ${
        printer.status === 'UNRESPONSIVE' && 'opacity-40'
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}>
      {printer.status.toLowerCase() === 'unresponsive' && (
        <Button
          variant="minimal"
          className="delete-printer text-ui-denial-red"
          onClick={deletePrinter}>
          <XCircle className="icon h-4 w-4 text-ui-denial-red" />
        </Button>
      )}

      <div
        className={`w-full h-full text-white text-xs flex flex-col justify-between p-2 rounded-xl bg-bw-green
          ${printer.status === 'PRINTING' && 'bg-ui-confirmation-green'}
          ${printer.status.includes("FROM_SD") && 'bg-ui-confirmation-green'}
          ${printer.status === 'OPERATIONAL' && 'bg-bw-green'}
          ${printer.status === 'ERROR' && 'bg-ui-denial-red'}
          ${printer.status === 'UNRESPONSIVE' && 'bg-bw-green'}
          ${printer.uploadingFileProgress && 'pointer-events-none cursor-default'}`}
        onClick={openPrinterDetails}>
        {/* <PrinterStatusIndicator status={printer.status} /> */}
        <div className='grow flex items-center justify-center text-white/50'>
          {printer.status === "ERROR" &&
            <div className='flex items-center gap-1'>
              <Ban className='h-4 w-4'/>
              ERROR
            </div>
          }
          {printer.status === "STARTING" &&
            <div className='flex items-center gap-1'>
              STARTING (Maybe check printer)
            </div>
          }
          {printer.status === "PAUSED" &&
            <div className='flex items-center gap-1'>
              <PauseCircle className='h-4 w-4' />
              PAUSED
            </div>
          }
          {printer.status.includes("FROM_SD") && 
            <div className='flex items-center gap-1'>
              <LoaderCircle className={`w-4 h-4 animate-spin`} />
              SD CARD
            </div>
          }
          {printer.status.includes("UNRESPONSIVE") &&
            <div className='flex items-center gap-1'>
              <ZapOff className='h-4 w-4'/>
              DISCONNECTED
            </div>
          }
        </div>
        {printer.technicianReview && (
          <AlertCircle className="w-4 h-4 relative self-center" />
        )}
        {printer.uploadingFileProgress !== null && 
          <div className='absolute z-20 border border-4 border-bw-grey top-0 left-0 w-full h-full flex justify-center items-center gap-1 rounded-2xl overflow-hidden'>
            <div className='absolute bg-bw-green px-6 w-full h-full flex justify-start items-center gap-1 rounded-sm overlflow-hidden'>
              <div className='relative w-full p-0.5 bg-bw-dark-tinted-green rounded-full -top-1'>
                <div className='relative h-0.5 bg-bw-pale-sage/75 rounded-full transition-all duration-500 eaese-out-expo' style={{width: printer.uploadingFileProgress + '%'}}></div>
              </div>
            </div>
            <div className='relative flex items-center gap-1 z-20 top-2 text-bw-pale-sage/50 text-xxs uppercase font-medium animate-pulse'>
              Uploading
            </div>
          </div>
        }

        {(dragOver || sendingFile) && 
          <div className='absolute left-0 top-0 w-full h-full rounded-md
            flex items-center justify-center bg-bw-background-grey/90
            border border-2 border-bw-background-grey
            outline outline-2 outline-ui-info-purple
            font-bold uppercase text-ui-info-purple'>
            {sendingFile 
              ? <div className='flex items-center gap-1'>
                  <Loader className='h-4 w-4 animate-spin' />
                  Uploading
                </div>
              : <div>Drop to print</div>
            }
          </div>
        }

        <div className="info flex flex-col gap-1">
          {printer.status === 'PRINTING' && (
            <div className="text-bw-pale-sage flex flex-col gap-1">
              <div className="flex justify-between items-center">
                <div className="font-bold">{progress} %</div>
                <div className={`${timeLeft === 0 && 'animate-pulse opacity-5'}`}>
                  {timeLeft === 0
                    ? 'Calculating...'
                    : helpers.formatSecondsIntoHHMMSS(timeLeft)}
                </div>
              </div>
              <ProgressBar height={3} progress={progress} />
            </div>
          )}

          <div className="printer-info flex gap-1 justify-between items-center">
            <div
              className={`printer-name shrink gap-1 truncate font-medium ${
                printer.status === 'UNRESPONSIVE' && 'opacity-70'
              }`}>
              {printer.name}
            </div>
            <div className="filaments flex shrink-0 gap-1 hidden">
              <Filament
                color={printer.activeFilament.colour.hexCode || '#333333'}
                active
                small
              />
              <Filament
                color={printer.inactiveFilament.colour.hexCode || '#333333'}
                active
                small
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="flex justify-center mt-2">
        <input
          type="file"
          accept=".gcode"
          onChange={handleFileInputChange}
          className="hidden"
          id="fileUpload"
        />
        <label
          htmlFor="fileUpload"
          className="cursor-pointer flex items-center gap-2 bg-white text-black px-3 py-1 rounded-md">
          <UploadCloud size={16} />
          Upload GCode
        </label>
      </div> */}
      {printerDetailsOpen &&
        <PrinterDetail
          open={printerDetailsOpen}
          printerId={toggledPrinterId}
          callbackPrinterDetailsOpen={() => setPrinterDetailsOpen(false)}
        />
      }
    </div>
  )
}

export default Printer
